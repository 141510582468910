import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import React, { useContext, useEffect, useState } from 'react';
import { GateWaySockets } from 'shared/hooks/axon/gateway.types';

export const useAudioDeviceSelector = () => {
  const { handleStartListening, handleStopListening, isMuted } =
    useContext(IPCTSSessionContext);
  const { isSessionScreen, jssipAudioRef } = useContext(LandingPageContext);
  const [inputDevicesList, setInputDevicesList] = useState<MediaDeviceInfo[]>(
    []
  );
  const [outputDevicesList, setOutputtDevicesList] = useState<
    MediaDeviceInfo[]
  >([]);

  const listMediaDevices = async (deviceType: string) =>
    navigator.mediaDevices
      .enumerateDevices()
      .then((list) =>
        list.filter(
          (device) =>
            device.kind.includes(deviceType) &&
            !device.label.includes('(Virtual)')
        )
      );

  const setMediaDevicesList = async () => {
    await listMediaDevices('audioinput').then(setInputDevicesList);
    await listMediaDevices('audiooutput').then(setOutputtDevicesList);
  };

  useEffect(() => {
    setMediaDevicesList();
    navigator.mediaDevices.ondevicechange = async () => {
      await setMediaDevicesList();
    };
  }, []);

  const changeOutputDevice = React.useCallback(
    (deviceId: string) => {
      if (jssipAudioRef) {
        const audio = jssipAudioRef as HTMLAudioElement & {
          setSinkId(deviceId: string): void;
        };
        audio.setSinkId(deviceId);
      }
    },
    [jssipAudioRef]
  );

  const changeInputDevice = React.useCallback(async () => {
    if (isMuted || !isSessionScreen) return;

    await handleStopListening(GateWaySockets.captionerSocket);

    handleStartListening(GateWaySockets.captionerSocket);
  }, [handleStopListening, handleStartListening, isMuted]);

  return {
    inputDevicesList,
    outputDevicesList,
    changeOutputDevice,
    changeInputDevice,
  };
};

export enum PFCEvents {
  hello = 'hello',
  ready = 'ready',
  audioFinished = 'audio_finished',
  error = 'error',
  captions = 'captions',
  captionsEnded = 'captions_ended',
  sendAudio = 'send_audio',
  startAudio = 'start_audio',
  finalResult = 'final_result',
  correction = 'correction',
  correctionsFinished = 'corrections_finished',
}

import { config } from 'shared/config/cts.config';

import packageJson from '../../../../package.json';

const appVersion = packageJson.version;
class EventMessagePayload {
  private Metadata: Record<string, string>;

  constructor(payloadBody: Record<string, any>) {
    this.Metadata = {
      AWS_REGION: config.REACT_APP_AWS_ACCOUNT || '',
      AWS_ACCOUNT_ID: config.REACT_APP_DEPLOY_REGION || '',
    };
    Object.assign(this, payloadBody);
  }
}
type EventMessageData = {
  event: string;
  payload: EventMessagePayload;
};
class DataScienceEventMessage {
  private PartitionKey: string;

  private Data: EventMessageData;

  constructor(eventName: string, payload: EventMessagePayload) {
    this.PartitionKey = '6322971a-b413-4acf-bd6e-83f8b21c724e';
    this.Data = {
      event: eventName,
      payload,
    };
  }
}

class SessionLoginEventMessage extends DataScienceEventMessage {
  constructor(
    AgentSessionID: string,
    StartDt: string,
    LanguageCode: string,
    AgentID: string,
    HomeCallCenterID: string,
    HomeCallCenterName: string,
    SessionCallCenterID: string,
    SessionCallCenterName: string
  ) {
    super(
      'session_login',
      new EventMessagePayload({
        AgentSession: {
          AgentSessionID,
          StartDt,
          LanguageCode,
          AgentID,
          ApplicationCode: 'Captioner',
          ApplicationSoftwareVersion: appVersion,
          HomeCallCenterID,
          HomeCallCenterName,
          MachineID: '',
          SessionCallCenterID,
          SessionCallCenterName,
          State: 'login',
          StationNumber: -1,
        },
      })
    );
  }
}
class SessionLogoutEventMessage extends DataScienceEventMessage {
  constructor(
    AgentSessionID: string,
    AgentID: string,
    EndDt: string,
    ModifiedDt: string
  ) {
    super(
      'session_logout',
      new EventMessagePayload({
        AgentSession: {
          AgentSessionID,
          AgentID,
          EndDt,
          ModifiedDt,
          ApplicationCode: 'Captioner',
          ApplicationSoftwareVersion: appVersion,
          State: 'logout',
        },
      })
    );
  }
}

export const useSendDataScienceEventMessage = () => {
  async function putEventMessage(
    userToken: string,
    message: DataScienceEventMessage
  ) {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', `Bearer ${userToken}`);
    try {
      await fetch(
        `${config.REACT_APP_CAPTIONER_SERVICES_API}/datascience-message`,
        {
          method: 'PUT',
          headers: requestHeaders,
          mode: 'cors',
          body: JSON.stringify(message),
        }
      );
    } catch (error) {
      // Might not be in AuthenticationContext so currently
      // cannot use usePostDynatraceLogs
      // eslint-disable-next-line no-console
      console.error('putEventMessage failed with ', error);
    }
  }

  const sendDataScienceEventMessage = async (
    userToken: string,
    message: DataScienceEventMessage
  ) => {
    await putEventMessage(userToken, message);
  };
  const sendSessionLoginEventMessage = async (
    userToken: string,
    AgentSessionID: string,
    StartDt: string,
    LanguageCode: string,
    AgentID: string,
    HomeCallCenterID: string,
    HomeCallCenterName: string,
    SessionCallCenterID: string,
    SessionCallCenterName: string
  ) => {
    const message: SessionLoginEventMessage = new SessionLoginEventMessage(
      AgentSessionID,
      StartDt,
      LanguageCode,
      AgentID,
      HomeCallCenterID,
      HomeCallCenterName,
      SessionCallCenterID,
      SessionCallCenterName
    );
    await putEventMessage(userToken, message);
  };
  const sendSessionLogoutEventMessage = async (
    userToken: string,
    AgentSessionID: string,
    AgentID: string,
    EndDt: string,
    ModifiedDt: string
  ) => {
    const message: SessionLogoutEventMessage = new SessionLogoutEventMessage(
      AgentSessionID,
      AgentID,
      EndDt,
      ModifiedDt
    );
    await putEventMessage(userToken, message);
  };

  return {
    sendDataScienceEventMessage,
    sendSessionLoginEventMessage,
    sendSessionLogoutEventMessage,
  };
};

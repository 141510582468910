import styled from 'styled-components';

export const TimeWrapper = styled.div`
  color: ${({ theme }) => theme.colors.chronoColor};
  font-size: 60px;
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  font-weight: 600;
  text-align: center;
  margin-bottom: 48px;
`;

/* eslint-disable */
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import { useContext, useRef } from 'react';
import { config } from 'shared/config/cts.config';
import { FinalResult } from 'shared/datasources/agent-service/entities.dto';
import { useCorrections } from 'shared/hooks/axon/corrections/use-corrections.hook';
import {
  CaptionShard,
  incomingAudioSampleRate,
} from 'shared/hooks/axon/gateway.types';
import { handleAudio } from 'shared/hooks/axon/helpers/handle-audio.helper';
import { useUpdateTranscript } from 'shared/hooks/axon/ipcts-gw/use-update-transcript.hook';
import { SoundBuffer } from 'shared/hooks/axon/ipcts-pfc-gw/SoundBuffer';
import { PFCEvents } from 'shared/hooks/axon/ipcts-pfc-gw/pfc-events.enum';
import useusePFCS3 from 'shared/hooks/axon/use-pfc-s3.hook';

import { demoLog } from 'shared/utils/demo-log';
import { useSocketFactory } from 'shared/utils/socket-factory/use-socket-factory.hook';

interface PFCGateWayInterface {
  setCallId: React.Dispatch<React.SetStateAction<string>>;
  captionShards: React.MutableRefObject<CaptionShard[]>;
  agentId: string;
}

const usePFCGateWay = ({
  setCallId,
  captionShards,
  agentId,
}: PFCGateWayInterface) => {
  const { getUserToken } = useContext(AuthenticationContext);
  const { post } = useusePFCS3();
  const callAudioCtx = useRef<AudioContext>(new AudioContext());
  const soundBuffer = new SoundBuffer(
    callAudioCtx.current,
    incomingAudioSampleRate,
    2
  );
  const handleIncommingAudio = async (binaryData: Blob) => {
    handleAudio(binaryData, soundBuffer);
  };

  const { updateTranscript } = useUpdateTranscript();

  const gateWaySocket = useSocketFactory({
    wsUrl: config.REACT_APP_PFC_GW_SOCKET!,
    handleIncommingAudio,
  });

  const { send, openSocket, closeSocket, setEventsHandlers } = gateWaySocket();
  const {
    sendCorrection: sendPFCCorrection,
    finishCorrections: finishPFCCorrections,
  } = useCorrections(send);

  const sendPFCRestult = (result: FinalResult) => {
    post({
      data: {
        agent_id: agentId,
        date: new Date().toISOString(),
        mic_pass: true,
        sound_pass: true,
        network_pass: true,
        score: result.score,
        suitable_for_calls: result.suitable_for_calls,
      },
    });
  };

  const closePFCConnections = async () => {
    try {
      captionShards.current = [];
      await callAudioCtx.current?.suspend();
      closeSocket();
      return await Promise.resolve();
    } catch (error) {
      demoLog('Audio Contexts closed');
      return await Promise.reject();
    }
  };

  const eventHandlers: Record<string, (value?: any) => void> = {
    [PFCEvents.hello]: () =>
      send(
        JSON.stringify({
          event: 'authenticate',
          payload: {
            token: getUserToken(),
          },
        })
      ),
    [PFCEvents.ready]: (msg: any) => {
      setCallId(msg.payload.session_id);
      demoLog('session ID: ', msg.payload.session_id);

      send(
        JSON.stringify({
          event: PFCEvents.sendAudio,
          payload: {
            detailed_shards: true,
          },
        })
      );
    },
    [PFCEvents.startAudio]: () => {
      demoLog('start audio event received');
    },
    [PFCEvents.audioFinished]: () => {
      finishPFCCorrections();
    },
    [PFCEvents.error]: (msg: any) => {
      demoLog('ERROR', msg);
    },
    [PFCEvents.captions]: (msg: any) => {
      updateTranscript(msg.payload);
    },
    [PFCEvents.captionsEnded]: () => {
      closePFCConnections();
    },
    [PFCEvents.finalResult]: (msg: any) => {
      demoLog('final result', msg.payload);
      sendPFCRestult(msg.payload);
      closeSocket();
    },

    status: (msg: any) => demoLog('[socket msg] %o', msg),
  };

  const openPFCConnection = () => {
    callAudioCtx.current.resume();
    setEventsHandlers(eventHandlers);
    openSocket();
  };

  return {
    openPFCConnection,
    closePFCConnections,
    sendPFCCorrection,
    finishPFCCorrections,
  };
};

export default usePFCGateWay;

export enum Entity {
  AddCallProperty = '/add-call-property',
  GetCallRecord = '/get-call-record',
}

export enum ESilentOptions {}

export enum ECallModes {
  TwoParty = 'TwoParty',
  ThirdParty = 'ThirdParty',
  AnsweringMachine = 'AnsweringMachine',
}

export enum ETerminationReasonCode {
  Abandon = 'Abandon',
  AnswerTO = 'AnswerTO',
  Busy = 'Busy',
  Cancel = 'Cancel',
  DlRelay = 'DlRelay',
  FaxTone = 'FaxTone',
  Illegit = 'Illegit',
  NoAnswer = 'NoAnswer',
  NonBill = 'NonBill',
  Normal = 'Normal',
  NotReg = 'NotReg',
  OthLang = 'OthLang',
  RtqCancl = 'RtqCancl',
  SignMail = 'SignMail',
  Silent = 'Silent',
  SitTone = 'SitTone',
  SltAnsMn = 'SltAnsMn',
  StvCancl = 'StvCancl',
  NotStarted = 'NotStarted',
  Test = 'Test',
  Unknown = 'Unknown',
}

export enum ECallDisposition {
  Normal = 'Normal',
  AnsMn = 'AnsMn',
  Busy = 'Busy',
  NoAnswer = 'NoAnswer',
  Illegit = 'Illegit',
  Silent = 'Silent',
  SltAnsMn = 'SltAnsMn',
  SitTone = 'SitTone',
  OthLang = 'OthLang',
  FaxTone = 'FaxTone',
  Unknown = 'Unknown',
}

export enum ESilentCallDisposition {
  NoTimer = 'NoTimer',
  TimerRunning = 'TimerRunning',
  MidCall = 'MidCall',
  ClearEnding = 'ClearEnding',
  Unknown = 'Unknown',
}

export enum ECallStateCode {
  AgntPush = 'AgntPush',
  AutoRjct = 'AutoRjct',
  Complete = 'Complete',
  InClRjct = 'InClRjct',
  InPrgWMi = 'InPrgWMi',
  InProg = 'InProg',
  Intrpret = 'Intrpret',
  ManRejct = 'ManRejct',
  Reconnct = 'Reconnct',
  RetrnToQ = 'RetrnToQ',
  Spawning = 'Spawning',
  WaitPush = 'WaitPush',
  Unknown = 'Unknown',
}

export type AddOrUpdateCallPropertyReq = {
  CallId: string;
  Properties: {
    CallCenterId: string;
    AgentId: string;
    CallStateCode: ECallStateCode;
    FirstCaptionTime: string;
    LastCaptionTime: string;
    CallAbandonedDt: string;
    AgentConnectionDt: string;
    AgentInitiatedCompletion: string;
    DeviceCallType: string;
    SwitchHook: number;
    AverageConfidenceScore: number;
    FinalResultsWordCount: number;
    AsrProviders: string[];
    TerminationReasonCode: ETerminationReasonCode;
    SilentCallDispositionSelectedByAgent: string;
    CaptionCorrectionsCount: number;
    VcaServerAddress: string;
    AgentIpAddress: string;
  };
};

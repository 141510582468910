import { Popover } from 'antd';
import { useState } from 'react';
import { CorrectionsPopUp } from 'shared/components/ui/editor/editor/CorrectionsPopUp';
import { PShard } from 'shared/components/ui/editor/editor/CorrectionsPopUp.styled';
import { zeroWidthJoiner } from 'shared/utils/separator-joiners.util';

interface IShardParagraphProps {
  shardText: string;
  shardId: number;
  alternatives: string[];
}

export const ShardParagraph = ({
  shardText,
  shardId,
  alternatives,
}: IShardParagraphProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isOnCorrection, setIsOnCorrection] = useState(false);
  const text = shardText
    .replaceAll('<c>', zeroWidthJoiner.html)
    .replaceAll('</c>', zeroWidthJoiner.html)
    .replaceAll('&apos;', "'");
  return (
    <Popover
      key={`shard-${shardId}-popup`}
      id={`shard-${shardId}-popup`}
      content={
        <CorrectionsPopUp
          shardId={shardId}
          text={text}
          alternatives={alternatives}
          onHide={() => setIsVisible(false)}
          isOpen={isVisible}
          setIsOnCorrection={setIsOnCorrection}
        />
      }
      trigger={'click'}
      visible={isVisible}
      onVisibleChange={() => setIsVisible(!isVisible)}
    >
      <PShard
        isOnCorrection={isOnCorrection}
        key={`shard-${shardId}`}
        id={`shard-${shardId}`}
        data-alternatives={JSON.stringify(alternatives)}
        className="item"
        data-id={shardId.toString()}
        onCopy={(e) => {
          e.preventDefault();
          navigator.clipboard.writeText('');
        }}
        onCut={(e) => {
          e.preventDefault();
          navigator.clipboard.writeText('');
        }}
        onPaste={(e) => false}
        onDragStart={(e) => false}
        data-text={text}
      >
        {text}
      </PShard>
    </Popover>
  );
};

import { IFeatureFlag, ILinkedRoute, IRouteModule } from '../routes.types';

function getModuleRoutes(module: IRouteModule, featureFlags: IFeatureFlag[]) {
  return module.routes
    .filter(
      (route) =>
        !route.featureFlags ||
        route.featureFlags.every(
          (feature) =>
            featureFlags.find((fe) => fe.name === feature.name)?.value ===
            feature.value
        )
    )
    .map(
      (route, routeKey): ILinkedRoute => ({
        module: module.name,
        component: route.component,
        path: route.paths,
        key: module.name + routeKey,
        showNavBar: !route.hideNavBar,
        showSideBar: !route.hideSideBar,
        providers: module.providers,
      })
    );
}

export const linkRoutes = (
  modules: IRouteModule[],
  featureFlags: IFeatureFlag[]
): ILinkedRoute[] =>
  modules.reduce(
    (linkedRoutes, module) => [
      ...linkedRoutes,
      ...getModuleRoutes(module, featureFlags),
    ],
    [] as ILinkedRoute[]
  );

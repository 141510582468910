import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import { config } from 'shared/config/cts.config';

const configuration: Configuration = {
  auth: {
    clientId: `${config.REACT_APP_MSAL_CONFIG_CLIENT_ID}`,
    authority: `${config.REACT_APP_MSAL_CONFIG_AUTHORITY}`,
    redirectUri: window.location.origin,
  },
};

const pca = new PublicClientApplication(configuration);

export const AuthProvider: React.FC = ({ children }) => (
  <MsalProvider instance={pca}>{children}</MsalProvider>
);

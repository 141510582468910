import useAxios from 'axios-hooks';
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import React from 'react';
import { config } from 'shared/config/cts.config';
import { AgentPFCResult } from 'shared/datasources/agent-service/entities.dto';

const useusePFCS3 = () => {
  const { getUserToken } = React.useContext(AuthenticationContext);
  const [
    { data: dataPFCResult, loading: loadingPFCResult, error: errorPFCResult },
    post,
  ] = useAxios<AgentPFCResult>(
    {
      baseURL: `${config.REACT_APP_CAPTIONER_SERVICES_API}`,
      url: `/pfc`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  return {
    post,
    dataPFCResult,
    loadingPFCResult,
    errorPFCResult,
  };
};

export default useusePFCS3;

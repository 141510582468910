import React, { useEffect, useRef } from 'react';
import { demoLog } from 'shared/utils/demo-log';

import * as Styled from './audio-visualizer.styled';

const BARS_QUANTITY = 12;

export const AudioVisualizer = ({ isMuted }: { isMuted: boolean }) => {
  const audioContextRef = useRef<AudioContext>();
  const analyserNodeRef = useRef<AnalyserNode>();
  const barsRef = useRef<HTMLDivElement[]>(Array(BARS_QUANTITY).fill({}));
  const animationFrameRef = useRef<number | null>(null);

  const getSignalAverage = (
    i: number,
    dataArray: Uint8Array,
    valuesPerBar: number
  ) => {
    let sum = 0;
    for (let j = 0; j < valuesPerBar; j++) {
      sum += dataArray[i * valuesPerBar + j];
    }
    return Math.round(sum / valuesPerBar);
  };

  const getColorStrength = (signalValue: number) =>
    Math.round(signalValue / (signalValue + 1)) || 0.1;

  const updateVisualizer = () => {
    if (!analyserNodeRef.current || !audioContextRef.current) return;

    const analyserNode = analyserNodeRef.current;
    const dataArray = new Uint8Array(analyserNode.frequencyBinCount);
    analyserNode.getByteFrequencyData(dataArray);

    const valuesPerBar = Math.floor(dataArray.length / BARS_QUANTITY);

    barsRef.current.forEach((bar, index) => {
      if (!bar) return;
      if (isMuted) {
        // eslint-disable-next-line no-param-reassign
        bar.style.backgroundColor = 'rgba(149, 201, 64, 0.1)';
        return;
      }
      const averageSignalValue = getSignalAverage(
        index,
        dataArray,
        valuesPerBar
      );
      // eslint-disable-next-line no-param-reassign
      bar!.style.backgroundColor = `rgba(149, 201, 64, ${getColorStrength(
        averageSignalValue
      )})`;
    });

    animationFrameRef.current = requestAnimationFrame(updateVisualizer);
  };

  const initAudioContext = async () => {
    try {
      const audioContext = new AudioContext();
      const analyserNode = audioContext.createAnalyser();
      analyserNode.fftSize = 32;

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { noiseSuppression: true, autoGainControl: true },
      });

      const microphoneSource = audioContext.createMediaStreamSource(stream);
      microphoneSource.connect(analyserNode);

      audioContextRef.current = audioContext;
      analyserNodeRef.current = analyserNode;

      if (!barsRef.current.every((bar) => bar !== null)) {
        barsRef.current = barsRef.current.map((_, index) => {
          if (!barsRef.current[index]) {
            const bar = document.createElement('div');
            return bar;
          }
          return barsRef.current[index];
        });
      }

      animationFrameRef.current = requestAnimationFrame(updateVisualizer);
    } catch (error) {
      demoLog('Error initializing audio context:', error);
    }
  };

  useEffect(() => {
    initAudioContext();

    return () => {
      if (animationFrameRef.current)
        cancelAnimationFrame(animationFrameRef.current);
      if (audioContextRef.current) audioContextRef.current.close();
    };
  }, [isMuted]);

  return (
    <Styled.Visualizer>
      {barsRef.current.map((bar, index) => (
        <Styled.Bar
          key={index}
          ref={(el) => {
            barsRef.current[index] = el!;
          }}
        />
      ))}
    </Styled.Visualizer>
  );
};

import ARROWUP from 'assets/icons/select-arrow-up.svg';
import ARROW from 'assets/icons/select-arrow.svg';
import * as StyledControls from 'modules/ipcts-call-session/components/controls/call-controls.styled';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';

import React, { useContext, useState } from 'react';

interface ITextOptionsProps {
  showLabels?: boolean;
}

export const TextOptions = ({ showLabels = true }: ITextOptionsProps) => {
  const {
    setCaptionAreaFontSize,
    setCaptionAreaLineHeight,
    setCaptionAreaFontFamily,
  } = useContext(IPCTSSessionContext);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = React.useRef<Element>();

  const FONTS = [
    { value: 'Inter-SemiBold, sans serif', label: 'Inter' },
    { value: 'Arial', label: 'Arial' },
    { value: 'Verdana', label: 'Verdana' },
    { value: 'Roboto-Regular', label: 'Roboto' },
    { value: 'Helvetica', label: 'Helvetica' },
    { value: 'Tahoma', label: 'Tahoma' },
    { value: 'Archivo', label: 'Archivo' },
    { value: 'OpenSans', label: 'Open Sans' },
    { value: 'Lato', label: 'Lato' },
    { value: 'Futura', label: 'Futura' },
  ];

  const onChangeFontSize = (newValue: number) => {
    setCaptionAreaFontSize(newValue);
  };

  const onChangeLineHeight = (newValue: number) => {
    setCaptionAreaLineHeight(newValue);
  };

  const onChangeFontFamily = (newValue: string) => {
    setCaptionAreaFontFamily(newValue);
  };

  const handleDispositionAction = () => {
    setIsOpen(!isOpen);
    selectRef.current?.dispatchEvent(
      new Event('click', {
        bubbles: true,
        cancelable: false,
      })
    );
  };

  React.useEffect(() => {
    selectRef.current = document.querySelectorAll('.font-select').item(0);
  }, []);

  return (
    <div>
      <StyledControls.SettingsSubtitle className="text-title">
        Captioning Style
      </StyledControls.SettingsSubtitle>
      {showLabels && (
        <StyledControls.SettingsText className="test-subtitle">
          Font Style
        </StyledControls.SettingsText>
      )}
      <StyledControls.SelectButton
        id="font-dropdown-container"
        isOpen={isOpen}
        onClick={() => handleDispositionAction()}
      >
        <StyledControls.SelectDropDown
          bordered={false}
          className="font-select"
          defaultValue="Inter-SemiBold, sans serif"
          getPopupContainer={() =>
            document.querySelector('#font-dropdown-container')!
          }
          dropdownAlign={{ offset: [-7, 0] }}
          style={{ width: '84%' }}
          onChange={(value) => onChangeFontFamily(value as string)}
          showArrow={false}
          onClick={(event) => {
            setIsOpen(!isOpen);
            event.stopPropagation();
          }}
          options={FONTS}
        />
        <StyledControls.SVGIcon src={isOpen ? ARROWUP : ARROW} />
      </StyledControls.SelectButton>
      <StyledControls.TextStyleContainer>
        <StyledControls.SettingsText>Font Size</StyledControls.SettingsText>
        <StyledControls.SliderContainer id="fontSizeSliderContainer">
          <StyledControls.SettingsSlider
            id="fontSizeSlider"
            defaultValue={33}
            min={16}
            max={50}
            onChange={onChangeFontSize}
          />
        </StyledControls.SliderContainer>
      </StyledControls.TextStyleContainer>
      <StyledControls.TextStyleContainer>
        <StyledControls.SettingsText>Line Height</StyledControls.SettingsText>
        <StyledControls.SliderContainer id="lineHeightSliderContainer">
          <StyledControls.SettingsSlider
            id="lineHeightSlider"
            defaultValue={40}
            min={30}
            max={50}
            onChange={onChangeLineHeight}
          />
        </StyledControls.SliderContainer>
      </StyledControls.TextStyleContainer>
    </div>
  );
};

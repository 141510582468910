import SVG from 'react-inlinesvg';
import styled from 'styled-components';

type CallControlsBodyProps = {
  tabsHidden?: boolean;
};

const buttonStyle = `
display: block;
line-height: 40px;
text-decoration: none;
width: 24px;
height: 24px;
border-radius: 50px;
z-index: 10;
`;

export const CallControlsContainer = styled.div`
  border: 1px solid rgba(4, 8, 9, 0.08);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 460px;
  max-width: 460px;
  overflow: hidden;
  gap: 48px;
  margin-top: 48px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.callControlsHeaderText};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 24px;
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const PFCTitle = styled.span`
  color: ${({ theme }) => theme.colors.callControlsHeaderText};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  line-height: 34px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CCIcon = styled(SVG)`
  height: 34px;
  width: 34px;
  margin-right: 4px;
`;

export const CallControlsBody = styled.div<CallControlsBodyProps>`
  background-color: ${({ theme }) => theme.colors.transparent};
  padding: 0px 60px;
  height: 60vh;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 24px;

  border-bottom-left-radius: ${({ tabsHidden }) =>
    tabsHidden ? '8px' : '0px'};
  border-bottom-right-radius: ${({ tabsHidden }) =>
    tabsHidden ? '8px' : '0px'};
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.transparent};
  }

  ::-webkit-scrollbar-thumb {
    background: #a59d9d;
    border-radius: 5px;
  }
  overflow: auto;
`;

export const BlueBtn = styled.a`
  ${buttonStyle}
  background-color: #609BF0;
`;

export const PinkBtn = styled.a`
  ${buttonStyle}
  background-color: #F39BDF;
`;

export const GreenBtn = styled.a`
  ${buttonStyle}
  background-color: #28BFB2;
`;

export const YellowBtn = styled.a`
  ${buttonStyle}
  background-color: #FFCF87;
`;

export const WhiteBtn = styled.a`
  ${buttonStyle}
  background-color: #FFFFFF;
`;

import qs from 'qs';
import { config } from 'shared/config/cts.config';
import {
  LogLevelType,
  usePostDynatraceLogs,
} from 'shared/hooks/dynatrace/use-dynatrace';

export const useLogin = () => {
  const { log } = usePostDynatraceLogs();

  const login = (state: string) => {
    const queryParams = qs.stringify({
      response_type: 'code',
      client_id: config.REACT_APP_AUTH_PROVIDER_CLIENT_ID!,
      redirect_uri: window.location.origin,
      scope: config.REACT_APP_AUTH_PROVIDER_SCOPE!,
      state,
    });
    const newURL = `https://sso.indeo.com:9031/as/authorization.oauth2?${queryParams}`;
    try {
      window.location.replace(newURL);
    } catch (error: any) {
      log({
        logLevelType: LogLevelType.ERROR,
        methodName: 'login',
        parameters: { state },
        message: `Failed with error message ${error.message}`,
        errorStack: error.stack,
      });
    }
  };
  return { login };
};

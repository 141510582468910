import { Avatar, Drawer, Layout } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Content } from 'antd/lib/layout/layout';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const LayoutContent = styled(Layout.Content)``;

export const MainLayout = styled(Layout)`
  background: ${({ theme }) => theme.colors.mainBackground} !important;
  .ant-layout {
    background: ${({ theme }) => theme.colors.mainBackground};
  }
`;
export const CustomSider = styled(Sider)``;

export const BackgroundWrapper = styled(SVG)`
  position: fixed;
`;

export const DrawerTitle = styled.div`
  display: flex;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ThemeContainer = styled.div`
  background-color: hsl(0, 0%, 100%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid hsl(0, 0%, 87%);
`;

export const ThemeButton = styled.button`
  margin: 0 5px;
  padding: 10px;
  font-size: 0.5rem;
  border: 1px solid hsl(0, 0%, 87%);
  border-radius: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px 2px hsl(0, 0%, 87%);
  }
`;

export const HeaderLogotypeCaptionCall = styled.img`
  width: 34px;
  height: 20px;
  margin: 22px 0px 22px 16px;
  padding-right: 12px;
  border-right: 1px solid ${({ theme }) => theme.colors.borderColorGray};
`;

export const HeaderLogotypeSorenson = styled.img`
  width: 130px;
  height: 20px;
  margin: 22px 0px 22px 16px;
`;

export const CustomDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    min-width: 170px !important;
    width: 300px !important;
    .ant-drawer-content {
      background-color: ${({ theme }) => theme.colors.darkerGray};
      .ant-drawer-wrapper-body {
        .ant-drawer-body {
          padding: 20px 16px;
        }
        .ant-drawer-header {
          height: 64px;
          padding: 0px;
          background-color: ${({ theme }) => theme.colors.darkerGray};
          background-color: ${({ theme }) => theme.colors.primaryColorBase};
          border: none;
          display: flex;
          align-items: center;
          justify-content: left;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          .ant-drawer-title {
            color: ${({ theme }) => theme.colors.white};

            svg {
              height: 30px;
              width: auto;
            }
          }
          .ant-drawer-close {
            margin: 7px 0px 7px 0px;
            padding: 0;
            color: ${({ theme }) => theme.colors.white};
            .anticon-close {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 50px;
              height: 50px;
              svg {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }

      a {
        color: ${({ theme }) => theme.colors.primaryColorBase};
        display: flex;
        justify-content: center;
        svg {
          margin-right: 10px;
          width: auto;
          height: 20px;
        }
      }
    }
  }
`;

export const CustomLayout = styled(Layout)`
  flex-direction: column;
  margin-top: 0;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    flex-direction: row;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xl}) {
    flex-direction: row;
  }

  @media (max-width: '412px') {
    padding: 0px !important;
  }
`;

export const CustomContent = styled(Content)`
  background-color: ${({ theme }) => theme.colors.blackOne};
  float: right;
  padding: 0;
  margin: 0;
  min-height: 280px;
`;

export const CustomLayoutContent = styled(Layout)`
  @media (max-width: ${({ theme }) => theme.breakPoints.md}) {
    padding: 16px 18px !important;
  }
`;

export const UserInfoContainer = styled.div`
  width: 100%;
  height: auto;
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 15.5px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColorGray};
`;

export const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.inter_semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.primaryColorBase};
  line-height: 1;
  margin-bottom: 4px;
`;

export const Username = styled.div`
  line-height: 1.2;
  font-size: ${({ theme }) => theme.fontSizes['x-small']};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.primaryColorBase};
`;

export const AvatarIcon = styled(Avatar)`
  .ant-avatar-string {
    font-size: 16px;
  }
  background-color: ${({ theme }) => theme.colors.midnightBlue400};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;
`;

export const MenuItemContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-family: ${({ theme }) => theme.fonts.inter_semibold};
  color: ${({ theme }) => theme.colors.primaryColorBase};
`;

export class SoundBuffer {
  private chunks: AudioBufferSourceNode[] = [];

  private readonly MAX_INT16 = 0x7fff;

  private readonly BUFFER_SIZE = 16384;

  private readonly INT_BYTES = 2;

  private isPlaying: boolean = false;

  private startTime: number = 0;

  private lastChunkOffset: number = 0;

  private dataView: DataView;

  private buffer: ArrayBuffer;

  constructor(
    public audioContext: AudioContext,
    public sampleRate: number,
    public bufferSize: number
  ) {
    this.buffer = new ArrayBuffer(this.BUFFER_SIZE);
    this.dataView = new DataView(this.buffer);
  }

  removeChunk(source: AudioBufferSourceNode) {
    const index = this.chunks.indexOf(source);
    if (index !== -1) {
      this.chunks.splice(index, 1);
    }
  }

  private createChunk(chunk: Float32Array): AudioBufferSourceNode {
    const audioBuffer = this.audioContext.createBuffer(
      1,
      chunk.length,
      this.sampleRate
    );
    audioBuffer.getChannelData(0).set(chunk);
    const source = this.audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(this.audioContext.destination);

    source.onended = () => {
      this.chunks.splice(this.chunks.indexOf(source), 1);
      if (this.chunks.length === 0) {
        this.isPlaying = false;
        this.startTime = 0;
        this.lastChunkOffset = 0;
      }
    };

    return source;
  }

  littleEndianInt16ToFloat32(littleEndianArray: Int16Array): Float32Array {
    const float32Array = new Float32Array(littleEndianArray.length);
    for (let i = 0; i < littleEndianArray.length; i++) {
      float32Array[i] = littleEndianArray[i] / this.MAX_INT16;
    }
    return float32Array;
  }

  public addChunk(data: Int16Array) {
    const newArray = this.littleEndianInt16ToFloat32(data);
    const chunk = this.createChunk(newArray);
    if (!chunk.buffer) {
      return;
    }

    chunk.onended = () => {
      this.removeChunk(chunk);
    };

    this.chunks.push(chunk);

    if (this.isPlaying) {
      chunk.start(this.startTime + this.lastChunkOffset);
      this.lastChunkOffset += chunk.buffer.duration;
    } else {
      if (this.chunks.length <= this.bufferSize) {
        return;
      }
      this.isPlaying = true;
      this.startTime = this.audioContext.currentTime;
      this.lastChunkOffset = 0;

      for (const chunk of this.chunks) {
        chunk.start(this.startTime + this.lastChunkOffset);
        this.lastChunkOffset += chunk.buffer!.duration;
      }
    }
  }
}

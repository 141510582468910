import { NOTIFICATION_CLASS_NAME } from 'shared/components/ui/alerts/notification-messages.alert';
import { css } from 'styled-components';

export const GlobalNotificationStyles = css`
  .ant-notification {
    .${NOTIFICATION_CLASS_NAME} .ant-notification-notice-message {
      margin-bottom: 0px;
    }

    .${NOTIFICATION_CLASS_NAME}.ant-notification-notice-success {
      background-color: ${({ theme }) => theme.colors.successNotification};

      .ant-notification-notice-message {
        color: ${({ theme }) => theme.colors.textNotification};
      }
    }

    .${NOTIFICATION_CLASS_NAME}.ant-notification-notice-error {
      background-color: ${({ theme }) => theme.colors.errorNotification};

      .ant-notification-notice-message {
        color: ${({ theme }) => theme.colors.textNotification};
      }
    }

    .${NOTIFICATION_CLASS_NAME}.ant-notification-notice-warning {
      background-color: ${({ theme }) => theme.colors.warningNotification};

      .ant-notification-notice-message {
        color: ${({ theme }) => theme.colors.textNotification};
      }
    }

    .${NOTIFICATION_CLASS_NAME}.ant-notification-notice-info {
      background-color: ${({ theme }) => theme.colors.infoNotification};

      .ant-notification-notice-message {
        color: ${({ theme }) => theme.colors.textNotification};
      }
    }
  }

  .ant-message {
    .ant-message-notice {
      .ant-message-notice-content {
        border-radius: 50px;
        .ant-message-warning,
        .ant-message-info,
        .ant-message-error,
        .ant-message-success {
          color: ${({ theme }) => theme.colors.textNotification};
        }
      }
      &.warning-message {
        .ant-message-notice-content {
          border: solid 2px
            ${({ theme }) => theme.colors.warningBorderNotification};
          background-color: ${({ theme }) => theme.colors.warningNotification};
        }
      }
      &.info-message {
        .ant-message-notice-content {
          border: solid 2px
            ${({ theme }) => theme.colors.infoBorderNotification};
          background-color: ${({ theme }) => theme.colors.infoNotification};
        }
      }
      &.error-message {
        .ant-message-notice-content {
          border: solid 2px
            ${({ theme }) => theme.colors.errorBorderNotification};
          background-color: ${({ theme }) => theme.colors.errorNotification};
        }
      }
      &.success-message {
        .ant-message-notice-content {
          border: solid 2px
            ${({ theme }) => theme.colors.successBorderNotification};
          background-color: ${({ theme }) => theme.colors.successNotification};
        }
      }
    }
  }
`;

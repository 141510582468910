const applicationRoutes = {
  ROOT: '/',
  CTS_LANDING: '/cts-web',
  SESSION: '/session/:id/:token',
  IPCTS_SESSION: '/ipcts_call',
  SESSIONLIST: '/sessionlist',
  KEYWORDLIST: '/keywordlist',
} as const;

export default applicationRoutes;

import * as React from 'react';

export type ComponentType = React.ElementType | React.ComponentType;

export enum RoutesModules {
  HOME = 'Home',
  SESSION = 'Session',
  IPCTS_SESSION = 'IPCTS Call',
  SESSIONLIST = 'List of sessions',
  KEYWORDLIST = 'List of keywords',
}

export interface IRouteAuth {
  authorized: boolean;
  private: boolean;
  forbidden?: boolean;
}

export interface ILinkedRoute {
  key: string;
  component: ComponentType;
  path: string[];
  module: string;
  showNavBar: boolean;
  showSideBar: boolean;
  providers?: ComponentType[];
}

export interface ICurrentRoute {
  path: string[];
  showNavBar: boolean;
  showSideBar: boolean;
  providers?: ComponentType[];
}

export interface ISideBar {
  isFormLink?: boolean;
  navTitle: string;
  path?: string;
  route: string;
  hideOnMobile?: boolean;
  isMainPath?: boolean;
  modulePath?: string;
}

export interface IFeatureFlag {
  name: string;
  value: boolean;
}
export interface IRoutes {
  paths: string[];
  component: ComponentType;
  hideNavBar?: boolean;
  hideSideBar?: boolean;
  permissions?: Permissions[];
  featureFlags?: IFeatureFlag[];
}

export interface IRouteModule {
  routes: IRoutes[];
  name: RoutesModules;
  providers?: ComponentType[];
}

export interface INavLink {
  module: RoutesModules;
  route: string;
  sidebar?: ISideBar[];
}
export interface INavLinks {
  settings: INavLink[];
  body: INavLink[];
}
export type RoutePathsFn = () => IRoutes[];
export type RouteModuleFn = () => IRouteModule;

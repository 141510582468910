/* eslint-disable no-useless-computed-key */
import { AppstoreFilled, SoundFilled } from '@ant-design/icons';
import SorensonLogoMobile from 'assets/icons/sorenson-logo-mobile.svg';
import UserCommand from 'assets/icons/user-command.svg';

import { AuthenticationContext } from 'modules/authentication/authentication.context';
import applicationRoutes from 'modules/routing/routes';
import { ThemeContext } from 'modules/theme/theme.context';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DesktopHeader from 'shared/components/layout/components/desktop-header/header.component';

import useWindowDimensions from 'shared/hooks/use-window-dimensions';

import theme, {
  ThemeType,
  blueTheme,
  darktheme,
  greenTheme,
  pinkTheme,
  whiteTheme,
  yellowTheme,
} from 'shared/styles/theme';

import { ThemeProvider } from 'styled-components';

import * as Styled from './layout.styled';

export interface LayoutProps {
  children: React.ReactNode;
}

const TheLayout: React.FC<LayoutProps> = ({ children }) => {
  const { user } = React.useContext(AuthenticationContext);
  const { accent } = React.useContext(ThemeContext);
  const [visibleDrawer, setVisibleDrawer] = React.useState(false);
  const { isDesktop } = useWindowDimensions();
  const [selectedTheme, setSelectedTheme] = React.useState<ThemeType>(theme);

  const HandleThemeChange = (themeValues: any) => {
    sessionStorage.setItem('currentTheme', themeValues.name.id);
    setSelectedTheme(themeValues);
  };
  const toggleVisibleDrawer = () => {
    setVisibleDrawer((preVisibleDrawer) => !preVisibleDrawer);
  };

  const themes: any = {
    ['light']: () => HandleThemeChange(theme),
    ['dark']: () => HandleThemeChange(darktheme),
    ['green']: () => HandleThemeChange(greenTheme),
    ['blue']: () => HandleThemeChange(blueTheme),
    ['pink']: () => HandleThemeChange(pinkTheme),
    ['yellow']: () => HandleThemeChange(yellowTheme),
    ['white']: () => HandleThemeChange(whiteTheme),
  };

  useEffect(() => {
    themes[selectedTheme?.name.id]();
  }, [accent]);

  return (
    <ThemeProvider theme={accent}>
      <Styled.MainLayout
        style={{
          minHeight: '100%',
          display: 'inline-block',
          width: '100%',
        }}
        className="test"
      >
        <DesktopHeader isDesktop={isDesktop} />
        <Styled.CustomLayout>
          {!isDesktop && (
            <Styled.CustomDrawer
              title={
                <Styled.HeaderContainer>
                  <Styled.HeaderLogotypeSorenson src={SorensonLogoMobile} />
                </Styled.HeaderContainer>
              }
              placement="left"
              onClose={toggleVisibleDrawer}
              visible={visibleDrawer}
            >
              <Styled.UserInfoContainer>
                <Styled.AvatarIcon size={36}>
                  {user?.initials}
                </Styled.AvatarIcon>
                <div>
                  <Styled.Name>{user?.name}</Styled.Name>
                  <Styled.Username>{user?.username}</Styled.Username>
                </div>
              </Styled.UserInfoContainer>

              <Link
                to={applicationRoutes.CTS_LANDING}
                onClick={toggleVisibleDrawer}
                style={{ justifyContent: 'left' }}
              >
                <UserCommand />
                <Styled.MenuItemContainer>Captioner</Styled.MenuItemContainer>
              </Link>
              <Link
                to={applicationRoutes.SESSIONLIST}
                onClick={toggleVisibleDrawer}
                style={{ justifyContent: 'left', marginTop: '18px' }}
              >
                <AppstoreFilled
                  style={{
                    color: '#33b0a6',
                    paddingTop: '2px',
                  }}
                />
                <Styled.MenuItemContainer>
                  List of sessions
                </Styled.MenuItemContainer>
              </Link>
              <Link
                to={applicationRoutes.KEYWORDLIST}
                onClick={toggleVisibleDrawer}
                style={{ justifyContent: 'left', marginTop: '18px' }}
              >
                <SoundFilled
                  style={{
                    color: '#33b0a6',
                    paddingTop: '2px',
                  }}
                />
                <Styled.MenuItemContainer>
                  Voice Commands
                </Styled.MenuItemContainer>
              </Link>
            </Styled.CustomDrawer>
          )}

          <Styled.CustomLayoutContent>
            <Styled.CustomContent>{children}</Styled.CustomContent>
          </Styled.CustomLayoutContent>
        </Styled.CustomLayout>
      </Styled.MainLayout>
    </ThemeProvider>
  );
};

export default TheLayout;

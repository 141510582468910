export const PUNCTUATION = [
  JSON.stringify('.'),
  JSON.stringify(','),
  JSON.stringify(';'),
  JSON.stringify('?'),
  JSON.stringify('!'),
];
export const SEPARATORS_JOINERS = [
  JSON.stringify('\n'),
  JSON.stringify(' '),
  JSON.stringify('-'),
  ...PUNCTUATION,
];
// https://unicode-table.com/en/200D/ => invisible character to manage?.replacement words
export const zeroWidthJoiner = {
  unicode: /\u200D/,
  html: ' ', //&zwj;
  replace: '\u220D',
};

import { AuthenticationContext } from 'modules/authentication/authentication.context';
import React from 'react';
import { config } from 'shared/config/cts.config';
import { handoffService } from 'shared/config/rest.config';
import { RequestReqResponse } from 'shared/datasources/handoff-service/entities.dto';

// This endpoint is not being used currently as it is part of Modern Relay.
// Will be kept for reference purposes.
const usePostRequestHandoff = () => {
  const { getUserToken } = React.useContext(AuthenticationContext);
  const [
    {
      data: dataPostRequestHandoff,
      loading: loadingPostRequestHandoff,
      error: errorPostRequestHandoff,
    },
    post,
  ] = handoffService<RequestReqResponse>(
    {
      baseURL: `${config.REACT_APP_HANDOFF_SERVICE}`,
      url: '/request',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  return {
    post,
    dataPostRequestHandoff,
    loadingPostRequestHandoff,
    errorPostRequestHandoff,
  };
};

export default usePostRequestHandoff;

import Loader from 'modules/ipcts-call-session/components/controls/loader.component';
import React, { useState, useEffect } from 'react';
import * as Styled from 'shared/components/ui/fullScreenLoader/full-screen-loader.styled';
import SVGBackground from 'shared/components/ui/svgBackground/svgBackground';

const defaultShowSpinnerTimeout = 750;
const SpinnerDurationInSeconds = 1500;

const FullScreenLoader: React.FC = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setShowSpinner(true);
    }, defaultShowSpinnerTimeout);

    return () => {
      setShowSpinner(false);
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <>
      <SVGBackground />
      <Styled.Container>
        {showSpinner ? (
          <Loader
            durationInSeconds={SpinnerDurationInSeconds}
            type="small-loader"
          />
        ) : null}
      </Styled.Container>
    </>
  );
};

export default FullScreenLoader;

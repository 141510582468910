import CircleX from 'assets/icons/circle-x.svg';
import * as React from 'react';
import SVG from 'react-inlinesvg';

import * as Styled from './custom-401.styled';

type Custom401Type = {
  onLogout: () => void;
};

export const Custom401 = ({ onLogout }: Custom401Type) => {
  const handleLogout = () => {
    onLogout();
  };

  return (
    <Styled.AccessDeniedContainer>
      <Styled.AccessDeniedSection>
        <SVG src={CircleX} />
        <Styled.AccessDeniedBody>
          <Styled.AccessDeniedTitle>Access Denied</Styled.AccessDeniedTitle>
          <Styled.AccessDeniedContent>
            Your current permission do not allow you to access Captioner. Please
            contact your administrator.
          </Styled.AccessDeniedContent>
        </Styled.AccessDeniedBody>
        <Styled.AccessDeniedButton onClick={handleLogout}>
          <Styled.AccessDeniedButtonLabel>
            Close Captioner
          </Styled.AccessDeniedButtonLabel>
        </Styled.AccessDeniedButton>
      </Styled.AccessDeniedSection>
    </Styled.AccessDeniedContainer>
  );
};

import { useLDClient } from 'launchdarkly-react-client-sdk';

export type LDUser = {
  key?: string;
  name: string;
  email: string;
};

export const useLdIdentify = () => {
  const ldClient = useLDClient();

  const setLDUser = async (ldUser: LDUser) => {
    if (!ldClient) return;
    try {
      await ldClient?.identify(ldUser);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return { setLDUser };
};

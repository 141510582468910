import { Tooltip } from 'antd';
import { SelectProps } from 'rc-select';
import * as React from 'react';

import * as Styled from './dropdown-button.styled';
import { DropdownButtonType } from './dropdown-button.styled';

type CustomDropdownProps = Omit<
  SelectProps,
  'onChange' | 'placeholder' | 'value' | 'options'
> & {
  predefinedWidth?: boolean;
  type?: DropdownButtonType;
  updateDisplayValue?: boolean;
  options: { text: string; value: string; leftIcon?: JSX.Element }[];
  onChange: (value: string) => void;
  placeholder?: string;
  outlined?: boolean;
  loading?: boolean;
  disable?: boolean;
  value?: string | null;
  showTooltip?: boolean;
};

/**
 *
 * @param predefinedWidth If false, you will control the width wherever you use the component.
 * You may use this param for responsive stuff for example.
 */
const DropdownButton: React.FC<CustomDropdownProps> = ({
  disable,
  options,
  onChange,
  placeholder,
  updateDisplayValue = true,
  predefinedWidth = true,
  type = 'filled',
  loading,
  value,
  mode,
  defaultValue,
  showTooltip,
  ...restProps
}) => {
  const memoizedValue = React.useMemo(() => {
    if (updateDisplayValue) {
      return value || placeholder;
    }
    return placeholder;
  }, [placeholder, value, updateDisplayValue]);

  const tooltipValue = React.useMemo(() => {
    const newTooltipValue = options.find(
      (option) => option.value === memoizedValue
    );
    return newTooltipValue?.text || '';
  }, [memoizedValue, options]);

  return (
    <Tooltip
      title={tooltipValue}
      {...(!tooltipValue || !showTooltip ? { visible: false } : {})}
    >
      <Styled.CustomSelectStyled
        staticWidth={predefinedWidth}
        type={type}
        placeholder={placeholder}
        disabled={disable}
        value={memoizedValue || undefined}
        loading={loading}
        onChange={(value) => {
          if (value) {
            onChange(value.toString());
          }
        }}
        {...restProps}
        {...(loading ? { showArrow: true } : {})}
      >
        {options.map(({ text, value, leftIcon }) => (
          <Styled.CustomSelectStyled.Option key={value} value={value}>
            {leftIcon}
            {text}
          </Styled.CustomSelectStyled.Option>
        ))}
      </Styled.CustomSelectStyled>
    </Tooltip>
  );
};

export default DropdownButton;

import { ThemeContext } from 'modules/theme/theme.context';
import React, { useEffect, useState } from 'react';

import {
  blueTheme,
  darktheme,
  greenTheme,
  pinkTheme,
  whiteTheme,
  yellowTheme,
} from 'shared/styles/theme';

import * as Styled from './call-controls.styled';

interface IColorOptionsProps {
  showDot?: boolean;
}

export const ColorOptions = ({ showDot = false }: IColorOptionsProps) => {
  const { handleThemeChange, accent } = React.useContext(ThemeContext);
  const themeMapping: Record<string, any> = {
    accentMixed: darktheme,
    accentGreen: greenTheme,
    accentPink: pinkTheme,
    accentBlue: blueTheme,
    accentYellow: yellowTheme,
    accentWhite: whiteTheme,
  };
  const [selecteId, setSelectedId] = useState(
    themeMapping.accentMixed.name.testId
  );

  const selectedThemeChange = (e: HTMLElement) => {
    if (e.id === selecteId) {
      return;
    }
    document.getElementById(selecteId)?.classList.remove('selected');
    e.classList.add('selected');
    setSelectedId(e.id);
    handleThemeChange(themeMapping[e.id]);
  };

  useEffect(() => {
    setSelectedId(accent.name.testId);
    document.getElementById(accent.name.testId)?.classList.add('selected');
  }, []);
  return (
    <div>
      <Styled.AccentSection>
        <Styled.ThemesTitle className="color-title">
          Text Color
        </Styled.ThemesTitle>
        <Styled.ThemeOptionsContainer
          showDot={showDot}
          className="colors-selector"
        >
          <Styled.GreenBtn
            id="accentGreen"
            onClick={(e) => {
              selectedThemeChange(e.currentTarget);
            }}
          />
          <Styled.PinkBtn
            id="accentPink"
            onClick={(e) => {
              selectedThemeChange(e.currentTarget);
            }}
          />
          <Styled.BlueBtn
            id="accentBlue"
            onClick={(e) => {
              selectedThemeChange(e.currentTarget);
            }}
          />
          <Styled.YellowBtn
            id="accentYellow"
            onClick={(e) => {
              selectedThemeChange(e.currentTarget);
            }}
          />
          <Styled.WhiteBtn
            id="accentWhite"
            onClick={(e) => {
              selectedThemeChange(e.currentTarget);
            }}
          />
        </Styled.ThemeOptionsContainer>
      </Styled.AccentSection>
    </div>
  );
};

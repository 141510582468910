import Button from 'shared/components/ui/button.component';
import styled from 'styled-components';

export const TimerContainer = styled.div`
  height: 520px;
  width: 323px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DescriptionText = styled.p`
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  padding-top: 50px;
  color: #ffffffe3;
`;

export const Timer = styled.span`
  color: ${({ theme }) => theme.colors.primaryColorBase};
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_medium};
  font-size: 48px;

  position: relative;
  top: 75px;

  display: flex;
  justify-content: center;
  width: 448px;
`;

export const CancelButton = styled(Button)`
  background-color: #ffffff36 !important;
  color: #ffffff !important;
  height: 44px;
  margin: 0px 33px 0;
  width: 334px !important;
  top: -70px;
  &:hover {
    background-color: #ffffff36 !important;
    color: #ffffff !important;
  }
  &:focus {
    background-color: #ffffff36 !important;
    color: #ffffff !important;
  }
`;

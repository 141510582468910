import { DeleteOutlined } from '@ant-design/icons';
import { Divider, Input, Tooltip } from 'antd';
import React, { useContext, useEffect } from 'react';
import * as Styled from './CorrectionsPopUp.styled';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { GateWaySockets } from 'shared/hooks/axon/gateway.types';
import {
  ICorrectionsPopUpProps,
  keyEventsEnum,
} from 'shared/components/ui/editor/editor/CorrectionsPopUp.types';

export const CorrectionsPopUp = ({
  shardId,
  text,
  alternatives,
  onHide,
  isOpen,
  setIsOnCorrection,
}: ICorrectionsPopUpProps) => {
  const { sendShardCorrection } = useContext(IPCTSSessionContext);
  const [inputValue, setInputValue] = React.useState('');

  const handleAlternative = (alternative: string) => {
    sendShardCorrection(GateWaySockets.PFCSocket, shardId, alternative);
    setIsOnCorrection(true);
    onHide();
  };

  const handleCorrection = () => {
    sendShardCorrection(GateWaySockets.PFCSocket, shardId, inputValue);
    setIsOnCorrection(true);
    onHide();
  };

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const showAlternatives = () => {
    if (!alternatives.length || alternatives.length == 0) {
      return <span className="no-alternatives">No Alternatives available</span>;
    }
    return alternatives.slice(0, 3).map((alternative, index) => (
      <Styled.AltTag
        key={`alt-${index}`}
        color="green"
        onClick={(e) => handleAlternative(alternative)}
      >
        <p className="word">{alternative}</p>
        <Styled.NumberContainer className="number">
          {index + 1}
        </Styled.NumberContainer>
      </Styled.AltTag>
    ));
  };

  const keyEvents: { [key: string]: () => void } = {
    [keyEventsEnum.one]: () => handleAlternative(alternatives[0]),
    [keyEventsEnum.two]: () => handleAlternative(alternatives[1]),
    [keyEventsEnum.three]: () => handleAlternative(alternatives[2]),
    [keyEventsEnum.enter]: () => handleCorrection(),
    [keyEventsEnum.tab]: () => onHide(),
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!keyEvents[event.key]) {
      return;
    }
    keyEvents[event.key]();
  };

  useEffect(() => {
    if (!isOpen) return;
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, inputValue]);

  useEffect(() => {
    if (!isOpen) return;
    setInputValue(text);
  }, [isOpen]);

  return (
    <>
      <Styled.PopUpContainer className="test">
        <Input
          className="pop-up-input"
          bordered={false}
          value={inputValue}
          onChange={handleChange}
          suffix={
            <Tooltip title="Delete">
              <DeleteOutlined style={{ color: '#4e5558' }} />
            </Tooltip>
          }
        />
        <Divider orientation="left" style={{ margin: 0 }} />
        <Styled.AltRow>{showAlternatives()}</Styled.AltRow>
        <Styled.PopOverDivider orientation="left" />
        <Styled.ButtonsRow>
          <Styled.CorrectionButton onClick={onHide} className="skip-btn">
            <div className="word">Skip </div>
            <Styled.TabContainer className="tab">Tab</Styled.TabContainer>
          </Styled.CorrectionButton>
          <Styled.CorrectionButton onClick={handleCorrection}>
            Enter
          </Styled.CorrectionButton>
        </Styled.ButtonsRow>
      </Styled.PopUpContainer>
    </>
  );
};

import Button from 'shared/components/ui/button.component';
import DropdownButton from 'shared/components/ui/dropdown-button/dropdown-button.component';
import styled, { css } from 'styled-components';

export const OVERLAY_CLASS_NAME = 'overlay-popover-filters';

export const OverwriteFilterPopoverStyles = css`
  .${OVERLAY_CLASS_NAME} > .ant-popover-content > .ant-popover-arrow {
    display: none;
  }

  .${OVERLAY_CLASS_NAME}
    > .ant-popover-content
    > .ant-popover-inner
    > .ant-popover-title {
    color: ${({ theme }) => theme.colors.textColorBase};
    font-size: ${({ theme }) => theme.fontSizes.md};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;

export const FilterButton = styled(Button)`
  width: 104px;
  background-color: ${({ theme }) => theme.colors.white} !important;

  && > svg {
    margin-right: 8px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0 0.5rem 0;

  & > * {
    margin-bottom: 1rem;
  }

  & > *:last-child {
    margin-bottom: 0rem;
  }
`;

export const Dropdown = styled(DropdownButton)`
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  & > *:first-child {
    margin-right: 0.5rem;
  }

  & > *:last-child {
    margin-left: 0.5rem;
  }
`;

export const ActionButton = styled(Button)`
  width: 100%;
`;

import { Radio, RadioChangeEvent } from 'antd';
import React from 'react';

import * as Styled from './theme-selector.styled';

enum ThemeSelection {
  DarkTheme = 'darkThemeSelect',
  LightTheme = 'lightThemeSelect',
}

export const ThemeSelector = () => {
  const [selectedTheme, setSelectedTheme] = React.useState(
    ThemeSelection.DarkTheme
  );

  const handleThemeChange = (e: RadioChangeEvent) => {
    setSelectedTheme(e.target.value);
  };

  return (
    <Radio.Group onChange={handleThemeChange} value={selectedTheme}>
      <Styled.ThemeSelectorContainer>
        <Styled.ThemeGroup>
          <Styled.lightThemeCard>A</Styled.lightThemeCard>
          <Styled.ThemeSelector
            id={ThemeSelection.LightTheme}
            value={ThemeSelection.LightTheme}
          >
            Light theme
          </Styled.ThemeSelector>
        </Styled.ThemeGroup>
        <Styled.ThemeGroup>
          <Styled.darkThemeCard>A</Styled.darkThemeCard>
          <Styled.ThemeSelector
            id={ThemeSelection.DarkTheme}
            value={ThemeSelection.DarkTheme}
          >
            Dark theme
          </Styled.ThemeSelector>
        </Styled.ThemeGroup>
      </Styled.ThemeSelectorContainer>
    </Radio.Group>
  );
};

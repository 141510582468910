import { config } from 'shared/config/cts.config';
import {
  LogLevelType,
  usePostDynatraceLogsNoContext,
} from 'shared/hooks/dynatrace/use-dynatrace';

export const useAppUser = () => {
  const { log } = usePostDynatraceLogsNoContext();
  async function getAppUser(userToken: string, activeDirectoryId: string) {
    let response;
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', `Bearer ${userToken}`);
    let user;
    try {
      response = await fetch(
        `${config.REACT_APP_CAPTIONER_SERVICES_API}/user?${new URLSearchParams({
          activeDirectoryId,
        }).toString()}`,
        {
          method: 'GET',
          headers: requestHeaders,
          mode: 'cors',
        }
      );
      if (response?.status === 200) {
        user = await response?.json();
      }
      return user;
    } catch (error: any) {
      log(userToken, {
        logLevelType: LogLevelType.ERROR,
        methodName: 'getAppUser',
        parameters: { activeDirectoryId },
        message: `Failed with error message ${error.message} status code ${response?.status}`,
        errorStack: error.stack,
      });
      throw error;
    }
  }

  return {
    getAppUser,
  };
};

import { useRef } from 'react';
import { audioSampleRate } from 'shared/hooks/axon/gateway.types';
import { float32ToBinary } from 'shared/hooks/axon/helpers/gateway-audio.helper';
import { demoLog } from 'shared/utils/demo-log';

export const useAgentMic = (send: (data: ArrayBuffer) => void) => {
  const audioCtx = useRef<AudioContext>();

  const closeAudioContext = async () => {
    if (!audioCtx.current) {
      return;
    }
    try {
      await audioCtx.current?.close();
    } catch (error) {
      demoLog('Audio Context closed');
    }
  };

  const mute = async () => {
    if (!audioCtx.current) {
      return;
    }
    try {
      await audioCtx.current?.suspend();
    } catch (error) {
      demoLog('mute failed');
    }
  };

  const unmute = async () => {
    if (!audioCtx.current) {
      return;
    }
    try {
      await audioCtx.current?.resume();
    } catch (error) {
      demoLog('unmute failed', error);
    }
  };

  const processAgentMicAudio = (stream: MediaStream) => {
    audioCtx.current = new AudioContext({
      latencyHint: 'interactive',
      sampleRate: audioSampleRate,
    });
    const source = audioCtx.current?.createMediaStreamSource(stream);
    const processor = audioCtx.current.createScriptProcessor(4096, 1);
    source.connect(processor);
    processor.connect(audioCtx.current.destination);
    processor.onaudioprocess = (e) => {
      const binaryData = float32ToBinary(e.inputBuffer.getChannelData(0));
      send(binaryData);
    };
    source.connect(processor).connect(audioCtx.current.destination);
  };

  const getMic = () => {
    const response = navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: localStorage.getItem('SelectedInputDevice') || 'default',
        sampleRate: audioSampleRate,
        sampleSize: 16,
        channelCount: 1,
        noiseSuppression: true,
        echoCancellation: true,
      },
      video: false,
    });
    response.then(processAgentMicAudio, () => demoLog('ERROR'));
  };

  return { getMic, mute, unmute, closeAudioContext };
};

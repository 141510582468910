export enum BluePallete {
  backgroundColor911 = '#FF695C21',
  borderColor = '#51DBCD',
  borderColor911 = '#FF695C',
  callControlsBody = '#04080A',
  callControlsHeader = '#143433',
  callControlsTab = '#000000',
  callControlsTabSelected = '#609BF0',
  callControlsTabSelectedText = '#000000',
  callControlsTabText = '#FFFFFF99',
  callControlsHeaderText = '#FFFFFF',
  callControlsHeaderTextPFC = '#609BF0',
  whiteFontColor = '#FFFFFF',
  whiteBackground = '#FFFFFF',
  blackBackground = '#000000',
  modalFontColor = '#24292e',
  colorText = '#78ADF9',
  queue911FontColor = 'rgba(255, 255, 255, 0.75)',
  fontColor = '#78ADF9',
  backgroundColor = '#78ADF9',
  cancelColor = '#FF695C',
  dispositionSVGDisabled = '#E5E6E6',
  handoffButton = '#181C1E',
  handoffCancelButton = '#391C1B',
  handoffCancelButtonText = '#FF695C',
  handoffNextCAButtonEnabled = '#51DBCD',
  handoffNextCAButtonEnabledText = '#000000',
  handoffRequestButtonDisabled = '#111416',
  handoffRequestButtonDisabledText = '#636465',
  handoffRequestButtonSubtext = '#C5C6C7',
  handoffRequestButtonText = '#FFFFFF',
  iconColor = '#609BF0',
  iconColorTwo = '#FFFFFF',
  mainBackground = '#080F13',
  muteButtonBackgroundColor = 'rgba(255, 255, 255, 0.13)',
  primaryColorBase = '#78ADF9',
  chronoColor = '#78ADF9',
  sessionBackground = '#04080A',
  sessionEditorLowerBackground = 'rgba(255, 255, 255, 0.1)',
  sliderRail = '#ffffff14',
  sliderTrack = '#609BF0',
  testButtonBackground = 'rgba(96, 155, 240, 0.21)',
  blueThemeColor = '#78ADF9',
  pinkThemeColor = '#F39BDF',
  greenThemeColor = '#95C940',
  whiteThemeColor = '#FFFFFF',
  yellowThemeColor = '#FFCF87',
}

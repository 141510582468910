import useAxios from 'axios-hooks';
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import React from 'react';
import { config } from 'shared/config/cts.config';

import {
  Entity,
  GetAgentResponse,
} from 'shared/datasources/agent-service/entities.dto';

// This endpoint is not being used currently as it is part of Modern Relay.
// Will be kept for reference purposes.
const useGetAgent = () => {
  const { getUserToken } = React.useContext(AuthenticationContext);
  const [
    { data: dataGetAgent, loading: loadingGetAgent, error: errorGetAgent },
    get,
  ] = useAxios<GetAgentResponse>(
    {
      baseURL: `${config.REACT_APP_AGENTS_SERVICE_API_REST_URL}`,
      url: `${Entity.Agent}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  return {
    dataGetAgent,
    loadingGetAgent,
    errorGetAgent,
    get,
  };
};

export default useGetAgent;

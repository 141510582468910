import { AuthenticationContext } from 'modules/authentication/authentication.context';
import { useContext } from 'react';
import { config } from 'shared/config/cts.config';
import { UserStates } from 'shared/hooks/admin/states.enum';
import {
  LogLevelType,
  usePostDynatraceLogsNoContext,
} from 'shared/hooks/dynatrace/use-dynatrace';

type UserState = {
  stateName: string;
  sessionId: string;
};
async function postUserState(userToken: string, userState: UserState) {
  const { log } = usePostDynatraceLogsNoContext();
  let response;
  try {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', `Bearer ${userToken}`);

    response = await fetch(
      `${config.REACT_APP_CAPTIONER_SERVICES_API}/user-state`,
      {
        method: 'POST',
        headers: requestHeaders,
        mode: 'cors',
        keepalive: true,
        body: JSON.stringify(userState),
      }
    );
  } catch (error: any) {
    log(userToken, {
      logLevelType: LogLevelType.ERROR,
      methodName: 'postUserState',
      parameters: { userState },
      message: `Failed with error message ${error.message} status code ${response?.status}`,
      errorStack: error.stack,
    });
  }
}
export const useUserState = () => {
  const { getUserToken, user } = useContext(AuthenticationContext);

  const updateUserState = async (stateName: UserStates) =>
    postUserState(getUserToken() as string, {
      stateName,
      sessionId: user?.sessionId as string,
    });

  return {
    updateUserState,
  };
};

export const useUserStateNoContext = () => {
  const updateUserState = async (userToken: string, userState: UserState) =>
    postUserState(userToken, userState);
  return {
    updateUserState,
  };
};

import ExpirationModal from 'modules/authentication/components/expiration-modal.component';
import { useOAuth } from 'modules/authentication/oauth.context';
import * as React from 'react';
import { setAccessToken } from 'shared/config/rest.config';
import { useLdIdentify } from 'shared/hooks/use-ld-identify.hook';

export const LANGUAGES_MAP = {
  english: 'English_US',
  spanish: 'Spanish_US',
};

export type User = {
  name?: string;
  username: string;
  expTime?: number;
  initials: string;
  language: string;
  callCenterId: string;
  sessionId?: string;
  employeeID?: string;
};
type AuthenticationContextType = {
  user: User | null;
  getUserToken: () => string | undefined;
  loginValidated: boolean;
  validateLogin: () => boolean;
  logout: () => void;
};

const AuthenticationContext = React.createContext(
  {} as any as AuthenticationContextType
);

declare let pendo: any;

const AuthenticationProvider: React.FC = ({ children }) => {
  const [loginValidated, setLoginValidated] = React.useState(true);
  const [showExpirationModal] = React.useState<boolean>(false);
  const [accessTokenWasSet, setAccessTokenWasSet] = React.useState(false);
  const { tokenUser, accessToken, logout } = useOAuth();
  const { setLDUser } = useLdIdentify();

  React.useEffect(() => {
    if (accessToken) {
      setAccessTokenWasSet(true);
      setAccessToken(accessToken);
    }
  }, [accessToken]);

  const user = React.useMemo(() => {
    let initials = '';
    if (tokenUser?.sub) {
      if (tokenUser?.sub.includes('_')) {
        const substring = tokenUser?.sub.substring(
          tokenUser?.sub.indexOf('_') + 1,
          tokenUser?.sub.indexOf('_') + 3
        );
        initials = substring;
      } else {
        const substring = tokenUser?.sub.substring(0, 2);
        initials = substring;
      }
    } else {
      initials = 'USER';
    }

    return {
      objectGUID: tokenUser?.objectGUID as string,
      name: tokenUser?.cn as string,
      username: tokenUser?.sub as string,
      expTime: tokenUser?.exp,
      firstName: tokenUser?.given_name as string,
      lastName: tokenUser?.sn as string,
      initials,
      language: tokenUser?.language as string,
      callCenterId: tokenUser?.callCenterId as string,
      sessionId: tokenUser?.jti,
      employeeID: tokenUser?.employeeID as string,
    };
  }, [tokenUser]);

  React.useEffect(() => {
    if (!setLDUser && !user) return;
    const ldUser = {
      key: user!.username,
      name: user!.name!,
      email: user!.username,
    };

    setLDUser(ldUser);

    pendo.initialize({
      visitor: {
        id: user!.username, // Required if user is logged in, default creates anonymous ID
        // email:           // Recommended if using Pendo Feedback, or NPS Email
        // full_name:       // Recommended if using Pendo Feedback
        // role:            // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  }, [user, setLDUser]);

  const getUserToken = React.useCallback(() => accessToken, [accessToken]);

  const validateLogin = () => {
    setLoginValidated(true);
    return true;
  };

  return (
    <AuthenticationContext.Provider
      value={{ user, getUserToken, loginValidated, validateLogin, logout }}
    >
      {user && accessTokenWasSet && (
        <>
          {children}

          <ExpirationModal
            isModalOpen={showExpirationModal}
            onCancel={() => {}}
            onSubmit={() => {}}
          />
        </>
      )}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContext, AuthenticationProvider };

import { toNumber, isFinite } from 'lodash';
import * as React from 'react';
import theme from 'shared/styles/theme';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  const [isMobile, setIsMobile] = React.useState(false);
  const [isTablet, setIsTablet] = React.useState(false);
  const [isDesktop, setIsDesktop] = React.useState(false);

  const tabletBreakpoint = React.useMemo(() => {
    const tabletBreakpointString = theme.breakPoints.md.substr(
      0,
      theme.breakPoints.lgp.indexOf('px')
    );
    const mobileBreakpoint = toNumber(tabletBreakpointString);

    return isFinite(mobileBreakpoint) ? mobileBreakpoint : 768;
  }, [theme.breakPoints]);

  const desktopBreakpoint = React.useMemo(() => {
    const tabletBreakpointString = theme.breakPoints.tablet?.replace('px', '');
    const desktopBreakpoint = toNumber(tabletBreakpointString);

    return isFinite(desktopBreakpoint) ? desktopBreakpoint : 1280;
  }, [theme.breakPoints]);

  React.useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    setIsMobile(windowDimensions.width < tabletBreakpoint);
    setIsTablet(
      windowDimensions.width >= tabletBreakpoint &&
        windowDimensions.width < desktopBreakpoint
    );
    setIsDesktop(windowDimensions.width >= desktopBreakpoint);
  }, [windowDimensions.width, tabletBreakpoint, desktopBreakpoint]);

  return { ...windowDimensions, isMobile, isTablet, isDesktop };
};

export default useWindowDimensions;

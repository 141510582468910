import { NetworkState } from 'shared/hooks/use-network-state';
import styled from 'styled-components';

export const Paragraph = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.callControlsHeaderText};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 16px;
`;

export const NetworkStatus = styled.span<{ state: NetworkState }>`
  color: ${({ state }) => {
    if (state === NetworkState.GOOD) return 'green';
    else if (state === NetworkState.POOR) return 'yellow';
    else return 'red';
  }};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 16px;
`;

import { useChronometer } from 'modules/ipcts-call-session/hooks/use-chronometer.hook';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import React, { useContext, useEffect } from 'react';
import { CallState } from 'shared/datasources/session-state-service/entities.dto';

import * as Styled from './chronometer.component.styled';

export const Chronometer = () => {
  const { callState } = useContext(LandingPageContext);
  const { time, setIsActive, resetTimer } = useChronometer();

  useEffect(() => {
    if (callState === CallState.TwoOnCall) {
      resetTimer();
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [callState]);

  const format = (number: number) =>
    String(number).length === 1 ? `0${number}` : `${number}`;

  return (
    <Styled.TimeWrapper id="callLengthTimer">
      {format(time.hours)}:{format(time.minutes)}:{format(time.seconds)}
    </Styled.TimeWrapper>
  );
};

import { CloseCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import React from 'react';

export const openErrorNotification = (
  placement: any,
  description: string,
  message: string
) => {
  notification.open({
    message,
    description,
    className: 'custom-class',
    icon: <CloseCircleOutlined style={{ color: '#108ee9' }} />,
    placement,
  });
};

const ErrorNotification = () => openErrorNotification('topRight', '', '');

export default ErrorNotification;

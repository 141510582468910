import { css } from 'styled-components';

import { CANCEL_CLASS, OK_CLASS, POPCONFIRM_ID } from './popconfirm.component';

export const PopconfirmStyles = css`
  #${POPCONFIRM_ID} .ant-popover-buttons > button {
    border-radius: ${({ theme }) => theme.borderRadius.base};
  }

  #${POPCONFIRM_ID} .ant-popover-buttons > .${CANCEL_CLASS} {
    color: ${({ theme }) => theme.colors.textColorBase};
    border-color: ${({ theme }) => theme.colors.inputsBorder};
  }

  #${POPCONFIRM_ID} .ant-popover-buttons > .${OK_CLASS} {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primaryColorBase};
    border-color: ${({ theme }) => theme.colors.primaryColorBase};
  }

  #${POPCONFIRM_ID} .ant-popover-buttons > .${CANCEL_CLASS}:hover {
    color: ${({ theme }) => theme.colors.primaryColorBase};
    border-color: ${({ theme }) => theme.colors.primaryColorBase};
  }

  #${POPCONFIRM_ID} .ant-popover-buttons > .${OK_CLASS}:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primaryColorBaseLighter};
    border-color: ${({ theme }) => theme.colors.primaryColorBaseLighter};
  }
`;

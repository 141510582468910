import { PopconfirmProps, Popconfirm as PopconfirmAntd } from 'antd';
import React from 'react';

export const POPCONFIRM_ID = 'custom-popconfirm';
export const OK_CLASS = 'custom-ok-button';
export const CANCEL_CLASS = 'custom-cancel-button';

const Popconfirm: React.FC<PopconfirmProps> = (props) => (
  <PopconfirmAntd
    id={POPCONFIRM_ID}
    okButtonProps={{ className: OK_CLASS }}
    cancelButtonProps={{ className: CANCEL_CLASS }}
    {...props}
  />
);

export default Popconfirm;

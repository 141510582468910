const config = {
  PORT: process.env.PORT,
  HTTPS: process.env.HTTPS,
  SSL_CRT_FILE: process.env.SSL_CRT_FILE,
  SSL_KEY_FILE: process.env.SSL_KEY_FILE,
  REACT_APP_API_REST_URL: process.env.REACT_APP_API_REST_URL,
  REACT_APP_CONNECTION_STRING: process.env.REACT_APP_CONNECTION_STRING,
  REACT_APP_MSAL_CONFIG_CLIENT_ID: process.env.REACT_APP_MSAL_CONFIG_CLIENT_ID,
  REACT_APP_MSAL_CONFIG_AUTHORITY: process.env.REACT_APP_MSAL_CONFIG_AUTHORITY,
  REACT_APP_AGENTS_SERVICE_API_REST_URL:
    process.env.REACT_APP_AGENTS_SERVICE_API_REST_URL,
  REACT_APP_API_REST_URL_AQ: process.env.REACT_APP_API_REST_URL_AQ,
  REACT_APP_CALL_DETAIL_RECORD_SERVICE_API_URL:
    process.env.REACT_APP_CALL_DETAIL_RECORD_SERVICE_API_URL,
  REACT_APP_HANDOFF_SERVICE: process.env.REACT_APP_HANDOFF_SERVICE,
  REACT_APP_SESSION_SERVICE: process.env.REACT_APP_SESSION_SERVICE,
  REACT_APP_HUB_URL: process.env.REACT_APP_HUB_URL,
  REACT_APP_GW_SOCKET: process.env.REACT_APP_GW_SOCKET,
  REACT_APP_PFC_GW_SOCKET: process.env.REACT_APP_PFC_GW_SOCKET,
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID:
    process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  REACT_APP_AUTH_PROVIDER_CLIENT_ID:
    process.env.REACT_APP_AUTH_PROVIDER_CLIENT_ID,
  REACT_APP_AUTH_PROVIDER_SCOPE: process.env.REACT_APP_AUTH_PROVIDER_SCOPE,
  REACT_APP_AUTH_API: process.env.REACT_APP_AUTH_API,
  REACT_APP_CONFIG_SERVICE: process.env.REACT_APP_CONFIG_SERVICE,
  REACT_APP_IS_CTS: process.env.REACT_APP_IS_CTS,
  REACT_APP_PENDO_API_KEY: process.env.REACT_APP_PENDO_API_KEY,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  AWS_SECRET_MANAGER_REGION: process.env.AWS_SECRET_MANAGER_REGION,
  SECRET_MANAGER_KEY_NAME: process.env.SECRET_MANAGER_KEY_NAME,
  REACT_APP_AWS_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY,
  REACT_APP_AWS_ACCESS_SECRET_KEY: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
  AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
  AWS_REGION: process.env.AWS_REGION,
  REACT_APP_DOWNLOAD_URL: process.env.REACT_APP_DOWNLOAD_URL,
  UNWANTED_TAGS: process.env.REACT_APP_UNWANTED_TAGS,
  REACT_APP_MR_TAKE_CALL: process.env.REACT_APP_MR_TAKE_CALL,
  REACT_APP_CAPTIONER_SERVICES_API:
    process.env.REACT_APP_CAPTIONER_SERVICES_API,
  REACT_APP_AWS_ACCOUNT: process.env.REACT_APP_AWS_ACCOUNT,
  REACT_APP_DEPLOY_REGION: process.env.REACT_APP_DEPLOY_REGION,
};

export { config };

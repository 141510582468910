export enum EUserStatus {
  BgnSess = 'BgnSess',
  EndSess = 'EndSess',
  Idle = 'Idle',
  InPrgres = 'InPrgres',
  Intrptng = 'Intrptng',
  Login = 'login',
  Logout = 'logout',
}

export enum CallState {
  TwoOnCall = 'TwoOnCall',
  OneOnCallEnd = 'OneOnCallEnd',
  NoneOnCall = 'NoneOnCall',
  OneOnCall = 'OneOnCall',
}

export enum EAppCode {
  CTSWeb = 'CTSWeb',
  VCA = 'VCA',
}

export enum ELenguageCode {
  English = 'English',
  Spanish = 'Spanish',
}

export type LoginSessionReqModel = {
  AgentId: number;
  IsVCA: boolean;
  CreatedDt: string;
  CallCenterId: number;
  LanguageCode: ELenguageCode;
  ApplicationCode: EAppCode;
  ClientSoftwareVersion: string;
};

export type LoginSessionRespModel = {
  SessionId: string;
  ActivityDt: string;
  UsrStatusTypeCode: EUserStatus;
};

export type LogoutSessionReqModel = {
  SessionId: string;
  AgentId: number;
  IsVCA: boolean;
  ActivityDt: string;
};

export type UpdateSessionReqModel = {
  SessionId: string;
  ActivityDt: string;
  UsrStatusTypeCode: EUserStatus;
};

import { Dispatch, SetStateAction } from 'react';

export type CallHandoffContextData = {
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
};

export const HANDOFFS = {
  BREAK_LUNCH: { ID: 'BREAK_LUNCH', LABEL: 'Break / Lunch' },
  MEETING: { ID: 'MEETING', LABEL: 'Meeting' },
  TRAINING: { ID: 'TRAINING', LABEL: 'Training' },
  CONFERENCE_CALL: { ID: 'CONFERENCE_CALL', LABEL: 'Conference Call' },
  END_OF_SHIFT: { ID: 'END_OF_SHIFT', LABEL: 'End of Shift' },
  ENGLISH_SPANISH: { ID: 'ENGLISH_SPANISH', LABEL: 'Spanish Speaker' },
  EMERGENCY: { ID: 'EMERGENCY', LABEL: 'Emergency' },
};

import { Dispatch, SetStateAction, useState } from 'react';

export enum LocalStorageKeys {
  tokens = 'tokenInfo',
  user = 'user',
  state = 'loginState',
  authTime = 'authTime',
}
export interface TokensInfo {
  accessToken: string;
  refreshToken: string;
}
export interface TokenUser {
  scope: string[];
  client_id: string;
  jti: string;
  sub: string;
  objectGUID: string;
  mail: string;
  cn: string;
  memberOf: string[];
  sn: string;
  given_name: string;
  exp: number;
  employeeID: string;
  // props coming from captioner db
  userId: number;
  agentNumber: number;
  agentEmail: string;
  isActive: boolean;
  no911Calls: boolean;
  creationDt: string;
  modifiedDt: string;
  modifiedBy: number;
  language: string;
  role: {
    id: number;
    name: string;
    permissions: any[];
  };
  callCenters: any[];
  callCenterId: string;
}

type SetValue<T> = Dispatch<SetStateAction<T>>;
export const useStateStorage = <T>(
  key: string,
  initialValue: T
): [T, SetValue<T>] => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return initialValue;
    }
  });
  const setValue: SetValue<T> = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return [storedValue, setValue];
};

import styled from 'styled-components';

type LoaderProps = {
  seconds: number;
};

export const BigLoader = styled.div<LoaderProps>`
  top: -90px;
  width: 210px;
  height: 210px;
  border: 16px solid rgba(112, 112, 112, 0.3);
  border-radius: 50%;
  position: relative;
  transform: rotate(45deg);
  &:after {
    content: '';
    position: absolute;
    inset: -16px;
    border-radius: 50%;
    border: 16px solid ${({ theme }) => theme.colors.fontColor};
    animation: p8 ${({ seconds }) => seconds}s linear;
  }
  @keyframes p8 {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
`;

export const SmallLoader = styled.div<LoaderProps>`
  top: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  border: 6px solid rgba(112, 112, 112, 0.3);
  border-radius: 50%;
  position: relative;
  transform: rotate(45deg);
  &:after {
    content: '';
    position: absolute;
    inset: -6px;
    border-radius: 50%;
    border: 6px solid ${({ theme }) => theme.colors.fontColor};
    animation: p8 ${({ seconds }) => seconds}s linear;
  }
  @keyframes p8 {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 100%;
`;

export const Code = styled.p`
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  font-size: ${({ theme }) => theme.fontSizes['6xl']};
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 0px;
`;

export const Message = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.md};
  text-align: center;
  color: ${({ theme }) => theme.colors.textColorBase};
`;

import { Layout } from 'antd';
import { CurrentRouteContext } from 'modules/routing/context/current-route.context';
import { ComponentType } from 'modules/routing/routes.types';

import * as React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import * as Styled from './route.component.styled';

interface RouteComponentProps {
  component: ComponentType;
  match: RouteChildrenProps<any, any>;
}

const RouteComponent: React.FC<RouteComponentProps> = ({
  component: Component,
  match,
}) => {
  const [currentRoute] = React.useContext(CurrentRouteContext);

  const layout = React.useMemo(
    () => (
      <Styled.RootLayout>
        <Layout>
          <Component {...match} />
        </Layout>
      </Styled.RootLayout>
    ),
    [match, Component]
  );

  const body = React.useMemo(() => {
    const providedLayout = currentRoute.providers || [];
    return providedLayout.reduceRight(
      (children, Provider) => <Provider>{children}</Provider>,
      layout
    );
  }, [currentRoute.providers, layout]);

  return body;
};

export default RouteComponent;

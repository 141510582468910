import { useFlags } from 'launchdarkly-react-client-sdk';
import { Custom401 } from 'modules/401/custom-401.component';
import NotFound from 'modules/routing/components/not-found/not-found.component';
import { useRoutes } from 'modules/routing/hooks/useRoutes';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { config } from 'shared/config/cts.config';

import AuthRoute from './components/auth/auth-route.component';

const AppRouter = () => {
  const { ctsScreen } = useFlags();
  const featureFlags = React.useMemo(
    () => [
      { name: 'ctsScreen', value: !!config.REACT_APP_IS_CTS && ctsScreen },
    ],
    [ctsScreen]
  );

  const [routes] = useRoutes(featureFlags);
  return (
    <Switch>
      {routes.map((route) => (
        <Route exact key={route.key} path={route.path}>
          {(match) => <AuthRoute match={match} linkedRoute={route} />}
        </Route>
      ))}
      <Route component={Custom401} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default React.memo(AppRouter);

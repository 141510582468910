import { notification } from 'antd';
import { useEffect, useRef, useState } from 'react';

const ALERT_UUID = '63b991ec-1d02-11ee-be56-0242ac120002';
const ALERT_MESSAGE = 'No internet connection detected';

export enum NetworkState {
  GOOD = 'Good',
  POOR = 'Poor',
  BAD = 'Bad',
  OFFLINE = 'Offline',
  UNAVALIBLE = 'Unavailable',
}

const getNetworkState = (rtt: number) => {
  if (rtt === undefined) {
    return NetworkState.UNAVALIBLE;
  }
  if (rtt < 100) {
    return NetworkState.GOOD;
  }
  if (rtt < 200) {
    return NetworkState.POOR;
  }
  return NetworkState.BAD;
};

export const useNetworkState = () => {
  const isOnline = useRef(true);

  const getNetworkInfo = () => ({
    effectiveType: (window.navigator as any).connection?.effectiveType,
    rtt: (window.navigator as any).connection?.rtt,
    downlink: (window.navigator as any).connection?.downlink,
    level: isOnline.current
      ? getNetworkState((window.navigator as any).connection?.rtt)
      : NetworkState.OFFLINE,
  });

  const [networkStatus, setNetworkStatu] = useState(getNetworkInfo());

  const updateState = () => {
    setNetworkStatu(getNetworkInfo());
  };

  const onlineHandler = () => {
    isOnline.current = true;
  };
  const offLineHandler = () => {
    isOnline.current = false;
  };

  const cleanEventListeners = () => {
    window.removeEventListener('online', onlineHandler);
    window.removeEventListener('offline', offLineHandler);
  };

  useEffect(() => {
    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offLineHandler);

    if (!(window.navigator as any).connection) {
      return () => {
        cleanEventListeners();
      };
    }

    (window.navigator as any).connection.addEventListener(
      'change',
      updateState
    );
    const intervalID = setInterval(() => {
      updateState();
    }, 5000);

    return () => {
      (window.navigator as any).connection.removeEventListener(
        'change',
        updateState
      );
      cleanEventListeners();
      clearInterval(intervalID);
    };
  }, []);

  if (!isOnline.current) {
    notification.error({
      key: ALERT_UUID,
      message: ALERT_MESSAGE,
      placement: 'topLeft',
      className: 'network-error-notification',
    });
  }

  return networkStatus;
};

import { SoundBuffer } from 'shared/hooks/axon/ipcts-pfc-gw/SoundBuffer';
import { demoLog } from 'shared/utils/demo-log';

export const handleAudio = async (
  binaryData: Blob,
  soundBuffer: SoundBuffer
) => {
  try {
    const arrayBuffer = await binaryData.arrayBuffer();

    if (arrayBuffer.byteLength % 2 !== 0) {
      return;
    }

    const array16 = new Int16Array(arrayBuffer);
    soundBuffer.addChunk(array16);
  } catch (error) {
    demoLog('Something failed with audio ', error);
  }
};

import { Button as ButtonAntd } from 'antd';
import { ButtonProps as ButtonPropsAntd } from 'antd/lib/button';
import styled from 'styled-components';

type ButtonType = 'filled' | 'outlined' | 'text' | 'white';

type ButtonProps = Omit<ButtonPropsAntd, 'type'> & {
  buttonType?: ButtonType;
  customPadding?: string;
  responsiveAllWidth?: boolean;
  applyMozillaStyle?: boolean;
};

export const Button = styled(ButtonAntd)<ButtonProps>`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  height: 48px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  padding: ${({ customPadding }) => customPadding};
  width: 320px !important;
  font-family: ${({ theme }) => theme.fonts.inter_semibold};

  @media (max-width: ${(props) => props.theme.breakPoints.sml}) {
    width: ${({ responsiveAllWidth }) => responsiveAllWidth && '100%'};
  }

  // Centering content
  & > *:first-child {
    margin-left: auto;
  }

  & > *:last-child {
    margin-right: auto;
  }
  border-width: ${({ buttonType, disabled, theme }) => {
    if (disabled) {
      return null;
    }
    switch (buttonType) {
      case 'outlined':
        return theme.borderRadius.sm;
      default:
        return 'none';
    }
  }} !important;

  @media (max-width: ${(props) => props.theme.breakPoints['4xl']}) {
    & > *:first-child {
      margin-left: ${({ applyMozillaStyle }) => {
        if (applyMozillaStyle) {
          return '0';
        } else return 'auto';
      }};
    }

    & > *:last-child {
      margin-right: ${({ applyMozillaStyle }) => {
        if (applyMozillaStyle) {
          return '0';
        } else return 'auto';
      }};
    }
  }

  color: ${({ theme, buttonType, disabled }) => {
    if (disabled) {
      switch (buttonType) {
        case 'outlined':
          return theme.colors.outlinedDisabledColor;
        case 'text':
          return theme.colors.outlinedDisabledColor;
        default:
          return theme.colors.outlinedDisabledColor;
      }
    }
    switch (buttonType) {
      case 'outlined':
        return theme.colors.primaryColorBase;
      case 'text':
        return theme.colors.primaryColorBase;
      case 'white':
        return theme.colors.white;
      default:
        return theme.colors.white;
    }
  }} !important;
  background-color: ${({ theme, buttonType, disabled }) => {
    if (disabled) {
      switch (buttonType) {
        case 'outlined':
          return theme.colors.backgroundColorDisabled;
        case 'text':
          return theme.colors.backgroundColorDisabled;
        case 'white':
          return theme.colors.backgroundColorDisabled;
        default:
          return theme.colors.backgroundColorDisabled;
      }
    }

    switch (buttonType) {
      case 'outlined':
        return theme.colors.white;
      case 'text':
        return theme.colors.transparent;
      case 'white':
        return theme.colors.transparent;
      default:
        return theme.colors.primaryColorBase;
    }
  }} !important;
  border-color: ${({ theme, buttonType, disabled }) => {
    if (disabled) {
      switch (buttonType) {
        case 'outlined':
          return theme.colors.whiteRGBA?.replace('{opacity}', '0.12');
        case 'text':
          return theme.colors.whiteRGBA?.replace('{opacity}', '0.12');
        case 'white':
          return theme.colors.whiteRGBA?.replace('{opacity}', '0.12');
        default:
          return theme.colors.transparent;
      }
    }
    switch (buttonType) {
      case 'outlined':
        return theme.colors.activeState;
      case 'text':
        return theme.colors.transparent;
      case 'white':
        return theme.colors.transparent;
      default:
        return theme.colors.transparent;
    }
  }} !important;

  // Icon
  span.anticon {
    line-height: 0.8;
  }

  svg {
    path {
      fill: ${({ theme, buttonType, disabled }) => {
        if (disabled) {
          switch (buttonType) {
            case 'outlined':
            case 'text':
              return theme.colors.grayRGBA?.replace('{opacity}', '0.6');
            case 'white':
              return theme.colors.white?.replace('{opacity}', '0.6');
            default:
              return theme.colors.primaryColorBase;
          }
        }
        switch (buttonType) {
          case 'outlined':
            return theme.colors.textColorBase;
          case 'text':
            return theme.colors.primaryColorBase;
          case 'white':
            return theme.colors.white;
          default:
            return theme.colors.primaryColorBase;
        }
      }} !important;
      transition: fill ${({ theme }) => theme.transitions.sm};
    }
  }

  &:hover {
    background-color: ${({ theme, buttonType, disabled }) => {
      if (disabled) {
        return null;
      }

      switch (buttonType) {
        case 'outlined':
          return theme.colors.activeStateRGBA?.replace('{opacity}', '0.4');
        case 'text':
          return theme.colors.transparent;
        case 'white':
          return theme.colors.transparent;
        default:
          return theme.colors.primaryColorBaseHover;
      }
    }} !important;
    border-color: ${({ theme, buttonType, disabled }) => {
      if (disabled) {
        return null;
      }
      switch (buttonType) {
        case 'text':
          return theme.colors.transparent;
        case 'white':
          return theme.colors.transparent;
        case 'outlined':
          return theme.colors.activeState;
        default:
          return theme.colors.transparent;
      }
    }} !important;
    color: ${({ theme, buttonType, disabled }) => {
      if (disabled) {
        return null;
      }

      switch (buttonType) {
        case 'text':
          return theme.colors.primaryColorBaseLighter;
        case 'outlined':
          return theme.colors.primaryColorBase;
        case 'white':
          return theme.colors.white;
        default:
          return theme.colors.white;
      }
    }} !important;

    svg {
      path {
        fill: ${({ theme, buttonType, disabled }) => {
          if (disabled) {
            return null;
          }
          switch (buttonType) {
            case 'text':
              return theme.colors.primaryColorBaseLighter;
            case 'outlined':
              return theme.colors.primaryColorBase;
            default:
              return theme.colors.white;
          }
        }} !important;
        transition: fill ${({ theme }) => theme.transitions.sm};
      }
    }
  }
  &:focus {
    background-color: ${({ theme, buttonType, disabled }) => {
      if (disabled) {
        return null;
      }

      switch (buttonType) {
        case 'outlined':
          return theme.colors.transparent;
        case 'text':
          return theme.colors.transparent;
        default:
          return theme.colors.primaryColorBasePressed;
      }
    }} !important;
    border-color: ${({ theme, buttonType, disabled }) => {
      if (disabled) {
        return null;
      }
      switch (buttonType) {
        case 'text':
          return theme.colors.transparent;
        case 'outlined':
          return theme.colors.activeState;
        default:
          return theme.colors.transparent;
      }
    }} !important;
    color: ${({ theme, buttonType, disabled }) => {
      if (disabled) {
        return null;
      }

      switch (buttonType) {
        case 'text':
          return theme.colors.primaryColorBase;
        case 'outlined':
          return theme.colors.primaryColorBase;
        default:
          return theme.colors.white;
      }
    }} !important;

    svg {
      path {
        fill: ${({ theme, buttonType, disabled }) => {
          if (disabled) {
            return null;
          }
          switch (buttonType) {
            case 'text':
              return theme.colors.primaryColorBase;
            case 'outlined':
              return theme.colors.primaryColorBase;
            default:
              return theme.colors.white;
          }
        }} !important;
        transition: fill ${({ theme }) => theme.transitions.sm};
      }
    }
  }
  &:active {
    color: ${({ theme, buttonType, disabled }) => {
      if (disabled) {
        return null;
      }

      switch (buttonType) {
        case 'outlined':
          return theme.colors.black;
        case 'text':
          return theme.colors.transparent;
        default:
          return theme.colors.primaryColorBasePressed;
      }
    }} !important;
    background-color: ${({ theme, buttonType, disabled }) => {
      if (disabled) {
        return null;
      }

      switch (buttonType) {
        case 'outlined':
          return theme.colors.activeStateRGBA?.replace('{opacity}', '0.4');
        case 'text':
          return theme.colors.transparent;
        default:
          return theme.colors.primaryColorBasePressed;
      }
    }} !important;
    border-color: ${({ theme, buttonType, disabled }) => {
      if (disabled) {
        return null;
      }

      switch (buttonType) {
        case 'outlined':
          return theme.colors.activeState;
        case 'text':
          return theme.colors.transparent;
        default:
          return theme.colors.transparent;
      }
    }} !important;
    box-shadow: ${({ theme, buttonType, disabled }) => {
      if (disabled) {
        return null;
      }

      switch (buttonType) {
        case 'outlined':
          return `0 0 6px 0 ${theme.colors.activeState}`;
        default:
          return 'none';
      }
    }} !important;
  }
`;

export default Button;

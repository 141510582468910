import styled from 'styled-components';

export const Visualizer = styled.div`
  width: 196px;
  height: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: end;

  .active {
    background-color: #95c940;
  }
`;
export const Bar = styled.div`
  width: 12px;
  height: 20px;
  border-radius: 4px;
  background-color: #34452b;
  margin: 0 4px;
  transition: height 0.1s;
`;

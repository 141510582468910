import { Radio } from 'antd';
import styled from 'styled-components';

export const ThemeSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0;
  width: 340px;

  .ant-radio-inner {
    border: 1px solid var(--stroke-resting-white-21, rgba(255, 255, 255, 0.21));
    background-color: transparent;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: black !important ;
    background-color: ${({ theme }) => theme.colors.fontColor};
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: black;
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: black;
  }
`;

export const ThemeGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 92px;
  width: 160px;
`;

export const lightThemeCard = styled.div`
  font-family: ${({ theme }) => theme.fonts.inter_bold};
  font-size: 36px;
  background-color: ${({ theme }) => theme.colors.whiteBackground};
  border-radius: 8px;
  width: 160px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
`;

export const darkThemeCard = styled.div`
  font-family: ${({ theme }) => theme.fonts.inter_bold};
  font-size: 36px;
  background-color: ${({ theme }) => theme.colors.blackBackground};
  color: white;
  border-radius: 8px;
  width: 160px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
`;

export const ThemeSelector = styled(Radio)`
  margin-top: 12px;
  width: 160px;
  color: ${({ theme }) => theme.colors.whiteFontColor};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 16px;
`;

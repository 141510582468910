import React, { useEffect, useState } from 'react';

import useGetFlags from 'shared/datasources/config-service/use-get-flags';

const AppConfigContext = React.createContext({ flags: {} });

const AppConfigContextProvider: React.FC = ({ children }) => {
  const [flags, setFlags] = useState({});
  const { get: getFlags } = useGetFlags();

  useEffect(() => {
    getFlags().then((response) => {
      const { FLAGS } = response.data;
      setFlags(FLAGS || {});
    });
  }, []);

  return (
    <AppConfigContext.Provider value={{ flags: flags || {} }}>
      {children}
    </AppConfigContext.Provider>
  );
};

export { AppConfigContext, AppConfigContextProvider };

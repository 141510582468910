import RouteComponent from 'modules/routing/components/route/route.component';
import { CurrentRouteContext } from 'modules/routing/context/current-route.context';
import * as React from 'react';
import { RouteChildrenProps } from 'react-router-dom';

import { ILinkedRoute } from '../../routes.types';

export interface IAuthRoute {
  linkedRoute: ILinkedRoute;
  match: RouteChildrenProps<any, any>;
}

const AuthRoute: React.FC<IAuthRoute> = ({ linkedRoute, match }) => {
  const { component, path, showNavBar, showSideBar, providers } = linkedRoute;

  const [, setCurrentRoute] = React.useContext(CurrentRouteContext);

  React.useEffect(() => {
    setCurrentRoute({ path, showNavBar, showSideBar, providers });
  }, [linkedRoute, path, showNavBar, showSideBar, providers, setCurrentRoute]);

  return <RouteComponent component={component} match={match} />;
};

export default React.memo(AuthRoute);

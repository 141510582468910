import styled from 'styled-components';
import { Button, Divider, Row } from 'antd';

export const CorrectionButton = styled(Button)`
  background-color: #14310f;
  border-radius: 5px;
  color: #469a04;
  justify-content: center;
  width: 75px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_medium};
  border: none;
  width: 40%;
  &:hover,
  :focus {
    background-color: #53d102;
    color: black;
  }
`;

export const PopUpContainer = styled.div`
  padding: 12px 16px;
  .input-popup {
    color: gray;
  }
  min-width: 350px;
`;

export const NumberContainer = styled.div`
  width: 14px;
  height: 14px;
  font-size: 10px;
  border-radius: 50%;
  background-color: #3f6644;
  color: #61a63a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export const TabContainer = styled.div`
  width: 20px;
  height: 11px;
  font-size: 10px;
  background-color: transparent;
  border: 0.5px solid #466348;
  color: #748f70;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export const AltTag = styled(Button)`
  width: fit-content;
  border-radius: 5px;
  background-color: #0f330e;
  color: #469a04;
  border: none;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_light};
  gap: 5px;

  .word {
    margin: 0;
  }

  &:hover,
  :focus {
    background-color: #53d102;
    color: black;

    .word {
      color: #040709;
    }

    .number {
      background-color: #010c03;
      color: #459426;
    }
  }
`;

export const AltRow = styled(Row)`
  margin: 15px 0 15px 0;
  display: flex;
  flexflow: row;
  justify-content: space-evenly;
`;

export const ButtonsRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .skip-btn {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

export const PopOverDivider = styled(Divider)`
  margin: 15px 0 15px -15px;
  border: 0.5px solid #4e5558;
  width: calc(100% + 30px);
`;

type isOnCorrection = {
  isOnCorrection: boolean;
};

export const PShard = styled.p<isOnCorrection>`
  color: ${(props) =>
    props.isOnCorrection
      ? ({ theme }) => theme.colors.red
      : ({ theme }) => theme.colors.fontColor};
`;

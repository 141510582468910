/* eslint-disable react/prop-types */

import {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  memo,
  SyntheticEvent,
} from 'react';
import { CaptionShard } from 'shared/hooks/axon/gateway.types';
import { ShardParagraph } from 'shared/components/ui/editor/editor/shardParagraph';

const createNewShardParagraph = (transcription: CaptionShard) => (
  <ShardParagraph
    key={`shard-${transcription.id}`}
    shardId={transcription.id}
    shardText={transcription.text}
    alternatives={transcription.alternatives}
  />
);
/**
 * Based on https://github.com/lovasoa/react-contenteditable
 * A simple component for an html element with editable contents.
 */
export const ContentEditable = memo(
  forwardRef(function ContentEditable(
    {
      className,
      disabled,
      onBlur,
      onKeyDown,
      onKeyUp,
      tagName,
      value,
      editorRef,
      ...rest
    }: ContentEditableProps,
    ref: ForwardedRef<HTMLDivElement>
  ) {
    return (
      <div {...rest} className={className} ref={ref}>
        {value?.map((item) => createNewShardParagraph(item))}
      </div>
    );
  })
);

export type ContentEditableEvent = SyntheticEvent<any, Event> & {
  target: { name?: string; value: string };
};

export interface ContentEditableProps extends HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  contentEditableRef?: (el: HTMLElement) => void;
  name?: string;
  onChange?: (event: ContentEditableEvent) => void;
  tagName?: string;
  value?: CaptionShard[];
  editorRef?: any;
  isCTS?: boolean;
}

export const float32ToBinary = (floatArray: Float32Array) => {
  const intArray = new Int16Array(floatArray.length);
  for (let i = 0; i < floatArray.length; i++) {
    intArray[i] = Math.round(floatArray[i] * 32767);
  }

  const buffer = new ArrayBuffer(intArray.length * 2);
  const view = new DataView(buffer);
  for (let i = 0; i < intArray.length; i++) {
    view.setInt16(i * 2, intArray[i], true);
  }
  return buffer;
};

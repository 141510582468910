import * as Styled from 'modules/ipcts-call-session/pfc-screen/components/network-status.styled';
import React from 'react';
import { useNetworkState } from 'shared/hooks/use-network-state';

export const NetworkStatus = () => {
  const networkState = useNetworkState();
  return (
    <Styled.Paragraph>
      Network Status:
      <Styled.NetworkStatus id="networkStatusText" state={networkState.level}>
        {` ${networkState.level}`}
      </Styled.NetworkStatus>
    </Styled.Paragraph>
  );
};

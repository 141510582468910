import { message } from 'antd';
import { ArgsProps } from 'antd/lib/message';

type JointContent = Omit<ArgsProps, 'duration'> & {
  duration?: number;
};

type NotificationsProps = {
  config: JointContent;
};

export const NOTIFICATION_CLASS_NAME = 'custom-notification-class';

const openNotificationWithIcon = ({
  config = { type: 'info', content: '' },
}: NotificationsProps) => {
  const newConfig = {
    ...config,
    duration: config.duration || 5,
    className: `${config.type}-message ${config.className || ''}`,
  };

  switch (newConfig.type) {
    case 'error':
      message.error(newConfig);
      break;
    case 'success':
      message.success(newConfig);
      break;
    case 'warning':
      message.warning(newConfig);
      break;
    case 'info':
    default:
      message.info(newConfig);
  }
};

export default openNotificationWithIcon;

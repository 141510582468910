import { AuthenticationContext } from 'modules/authentication/authentication.context';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useContext, useRef } from 'react';
import { config } from 'shared/config/cts.config';
import { useCorrections } from 'shared/hooks/axon/corrections/use-corrections.hook';
import { incomingAudioSampleRate } from 'shared/hooks/axon/gateway.types';
import { handleAudio } from 'shared/hooks/axon/helpers/handle-audio.helper';
import { GatewayEvent } from 'shared/hooks/axon/ipcts-gw/gateway-events.enum';
import { useAgentMic } from 'shared/hooks/axon/ipcts-gw/use-agent-mic.hook';
import { useUpdateTranscript } from 'shared/hooks/axon/ipcts-gw/use-update-transcript.hook';
import { SoundBuffer } from 'shared/hooks/axon/ipcts-pfc-gw/SoundBuffer';
import { demoLog } from 'shared/utils/demo-log';
import { useSocketFactory } from 'shared/utils/socket-factory/use-socket-factory.hook';

const useGateWay = () => {
  const { getUserToken } = useContext(AuthenticationContext);
  const { captionShards, callId, agentId, setIsCallActive, detailedShards } =
    useContext(LandingPageContext);
  const { updateTranscript } = useUpdateTranscript();
  const callAudioCtx = useRef<AudioContext>(new AudioContext());
  const soundBuffer = new SoundBuffer(
    callAudioCtx.current,
    incomingAudioSampleRate,
    2
  );

  const handleIncommingAudio = async (binaryData: Blob) => {
    handleAudio(binaryData, soundBuffer);
  };

  const useGateWaySocket = useSocketFactory({
    wsUrl: `${config.REACT_APP_GW_SOCKET}?call_id=${callId}&agent_id=${agentId}`,
    handleIncommingAudio,
  });

  const { send, openSocket, closeSocket, setEventsHandlers } =
    useGateWaySocket();
  const { sendCorrection, sendDeletion } = useCorrections(send);
  const { getMic, closeAudioContext, mute, unmute } = useAgentMic(send);

  const closeConnections = async () => {
    await closeAudioContext();
    captionShards.current = [];
    closeSocket();
  };

  const eventHandlers: Record<string, (value?: any) => any> = {
    [GatewayEvent.captions]: (msg: any) => updateTranscript(msg.payload),
    [GatewayEvent.hello]: () =>
      send(
        JSON.stringify({
          event: GatewayEvent.authenticate,
          payload: {
            token: getUserToken(),
          },
        })
      ),
    [GatewayEvent.ready]: (msg: any) => {
      demoLog('Connected to Call', msg.payload.call_id);
    },
    [GatewayEvent.startAudio]: () => getMic(),
    [GatewayEvent.error]: (msg: any) => {
      demoLog('ERROR', msg);
    },

    [GatewayEvent.captionsEnded]: () => {
      closeSocket();
    },

    [GatewayEvent.connectionClosed]: () => {
      setIsCallActive(false);
    },

    [GatewayEvent.status]: (msg: any) => demoLog('[socket msg] %o', msg),
    [GatewayEvent.alternativesUnavailable]: () => {
      detailedShards.current = false;
    },
  };

  const openConnection = async () => {
    setEventsHandlers(eventHandlers);
    await openSocket();
  };

  return {
    openConnection,
    closeConnections,
    sendCorrection,
    sendDeletion,
    mute,
    unmute,
  };
};

export default useGateWay;

import styled from 'styled-components';

export const TimeWrapper = styled.div`
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  margin-bottom: 9.5px;
  font-size: 19px;
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  text-align: center;
`;

import useAxios from 'axios-hooks';
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import React from 'react';
import { config } from 'shared/config/cts.config';

import { AppConfig } from 'shared/datasources/config-service/entities.dto';

const useGetFlags = () => {
  const { getUserToken } = React.useContext(AuthenticationContext);
  const [{ data, loading, error }, get] = useAxios<AppConfig>(
    {
      baseURL: `${config.REACT_APP_CAPTIONER_SERVICES_API}`,
      url: '/config',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  return {
    get,
    data,
    loading,
    error,
  };
};

export default useGetFlags;

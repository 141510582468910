import { AxiosPromise } from 'axios';
import qs from 'qs';
import { config } from 'shared/config/cts.config';
import { useAuthService } from 'shared/config/rest.config';

type ReturnValue<T> = [
  (code: string) => AxiosPromise<T>,
  (refreshTokenValue: string) => AxiosPromise<T>,
  [T | undefined, boolean, any]
];

type TokenResponse = {
  access_token: string;
  refresh_token: string;
};

const useAuthToken = (): ReturnValue<TokenResponse> => {
  const [
    {
      data: dataPostAgentService,
      loading: loadingPosAgentService,
      error: errorPostAgentService,
    },
    post,
  ] = useAuthService<TokenResponse>(
    {
      url: 'auth/token',
      method: 'POST',
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  const requestToken = (code: string) =>
    post({
      data: qs.stringify({
        grant_type: 'authorization_code',
        code,
        redirect_uri: window.location.origin,
        client_id: config.REACT_APP_AUTH_PROVIDER_CLIENT_ID!,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

  const refreshToken = (refreshTokenValue: string) =>
    post({
      data: qs.stringify({
        grant_type: 'refresh_token',
        refresh_token: refreshTokenValue,
        client_id: config.REACT_APP_AUTH_PROVIDER_CLIENT_ID!,
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

  return [
    requestToken,
    refreshToken,
    [dataPostAgentService, loadingPosAgentService, errorPostAgentService],
  ];
};

export default useAuthToken;

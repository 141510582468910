import { AuthProvider } from 'modules/authentication/msal-provider';

import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import theme from 'shared/styles/theme';
import { ThemeProvider } from 'styled-components';

import App from './App';
import 'antd/dist/antd.css';
import './fonts.css';
import 'shared/config/rest.config';
import 'shared/config/dates.config';
import 'shared/config/pendo.config';

document.execCommand('defaultParagraphSeparator', false, 'p');

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);

import styled from 'styled-components';

const CardsContainer = styled.div`
  flex-direction: column;
  padding: 1rem 1rem 1rem 1rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`;

export { CardsContainer };

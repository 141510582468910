import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useContext } from 'react';
import {
  Correction,
  ShardCorrection,
} from 'shared/hooks/axon/corrections/corrections.types';
import { PFCEvents } from 'shared/hooks/axon/ipcts-pfc-gw/pfc-events.enum';

const removeHtmlTags = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

const preserveSpaces = (oldText: string, newText: string): string => {
  const textWithoutTags = removeHtmlTags(oldText);
  const trimmedStr = textWithoutTags.trim();
  return oldText.replace(trimmedStr, newText);
};

export const useCorrections = (send: (data: string) => void) => {
  const { captionShards } = useContext(LandingPageContext);
  const sendCorrection = (shardId: number, newText: string) => {
    if (!captionShards?.current) {
      return;
    }
    const correction: Correction = {
      shard_id: shardId,
      old_text: captionShards.current[shardId].text,
      new_text: newText,
    };
    if (correction.old_text === correction.new_text) {
      return;
    }

    const ShardCorrectionMsg: ShardCorrection = {
      type: 'correction',
      correction_type: 'SHARD_CORRECTION',
      correction: {
        affected_shards: [correction],
      },
    };

    const msgToGateway = {
      event: PFCEvents.correction,
      payload: ShardCorrectionMsg,
    };

    captionShards.current[shardId].text = preserveSpaces(
      correction.old_text,
      correction.new_text
    );
    send(JSON.stringify(msgToGateway));
  };

  const sendDeletion = (shardId: number) => {
    const deletion = {
      type: 'correction',
      correction_type: 'DELETION',
      correction: {
        affected_shards: [shardId],
      },
    };
    send(JSON.stringify(deletion));
  };

  const finishCorrections = () => {
    const msgToGateway = {
      event: PFCEvents.correctionsFinished,
      payload: {},
    };
    send(JSON.stringify(msgToGateway));
  };

  return {
    sendCorrection,
    sendDeletion,
    finishCorrections,
  };
};

import { BluePallete } from 'shared/styles/blue-pallette';
import { GreenPallete } from 'shared/styles/green-pallete';
import { PinkPallete } from 'shared/styles/pink-pallette';
import { BasePalleteDark } from 'shared/styles/redesign-dark-pallette';

import { WhitePallete } from 'shared/styles/white-pallete';
import { YellowPallete } from 'shared/styles/yellow-pallete';

import { BasePallete } from './base-light-pallete';
import { BasePalleteLight } from './redesign-light-pallette';

export type ThemeType = typeof theme;

export const theme = {
  name: {
    id: 'light',
    testId: 'accentLight',
  },

  breakPoints: {
    'xxs': '280px',
    'xs': '320px',
    'sm': '375px',
    'sml': '500px',
    'md': '768px',
    'lg': '1024px',
    'lgp': '1200px',
    'ml': '1244px',
    'xl': '1280px',
    'tablet': '1281px',
    '2xl': '1440px',
    '2xml': '1470px',
    '2ll': '1560px',
    '3xl': '1680px',
    '4xl': '1790px',
    'full': '1920px',
  },

  borderRadius: {
    'none': '0',
    'sm': '0.125rem',
    'base': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  },
  colors: {
    ...BasePallete,
    ...BasePalleteLight,
  },
  fonts: {
    stabil_grotesk_light: 'StabilGrotesk-Light, sans serif',
    stabil_grotesk_medium: 'StabilGrotesk-Medium, sans serif',
    stabil_grotesk_regular: 'StabilGrotesk-Regular, sans serif',
    inter_bold: 'Inter-Bold, sans serif',
    inter_light: 'Inter-Light, sans serif',
    inter_medium: 'Inter-Medium, sans serif',
    inter_regular: 'Inter-Regular, sans serif',
    inter_semibold: 'Inter-SemiBold, sans serif',
  },
  fontSizes: {
    'xxs': '9px',
    'xs': '10px',
    's': '12px',
    'x-small': '12px',
    'sm': '14px',
    'md': '16px',
    'lg': '18px',
    'lg-bigger': '20px',
    'xl': '22px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '48px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  shadows: {
    shadow0: 'rgba(222, 224, 232, 0.5) 0 0 12px 0 ',
    shadow6: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  transitions: {
    xs: '0.2s',
    sm: '0.3s',
    md: '0.5s',
    lg: '1s',
    xl: '1.5s',
  },
};

export const darktheme = {
  name: {
    id: 'dark',
    testId: 'accentMixed',
  },

  breakPoints: {
    'xxs': '280px',
    'xs': '320px',
    'sm': '375px',
    'sml': '500px',
    'md': '768px',
    'lg': '1024px',
    'lgp': '1200px',
    'ml': '1244px',
    'xl': '1280px',
    'tablet': '1281px',
    '2xl': '1440px',
    '2xml': '1470px',
    '2ll': '1560px',
    '3xl': '1680px',
    '4xl': '1790px',
    'full': '1920px',
  },

  borderRadius: {
    'none': '0',
    'sm': '0.125rem',
    'base': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  },
  colors: {
    ...BasePallete,
    ...BasePalleteDark,
  },
  fonts: {
    stabil_grotesk_light: 'StabilGrotesk-Light, sans serif',
    stabil_grotesk_medium: 'StabilGrotesk-Medium, sans serif',
    stabil_grotesk_regular: 'StabilGrotesk-Regular, sans serif',
    inter_bold: 'Inter-Bold, sans serif',
    inter_light: 'Inter-Light, sans serif',
    inter_medium: 'Inter-Medium, sans serif',
    inter_regular: 'Inter-Regular, sans serif',
    inter_semibold: 'Inter-SemiBold, sans serif',
  },
  fontSizes: {
    'xxs': '9px',
    'xs': '10px',
    's': '12px',
    'x-small': '12px',
    'sm': '14px',
    'md': '16px',
    'lg': '18px',
    'lg-bigger': '20px',
    'xl': '22px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '48px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  shadows: {
    shadow0: 'rgba(222, 224, 232, 0.5) 0 0 12px 0 ',
    shadow6: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  transitions: {
    xs: '0.2s',
    sm: '0.3s',
    md: '0.5s',
    lg: '1s',
    xl: '1.5s',
  },
};

export const blueTheme = {
  name: {
    id: 'blue',
    testId: 'accentBlue',
  },

  breakPoints: {
    'xxs': '280px',
    'xs': '320px',
    'sm': '375px',
    'sml': '500px',
    'md': '768px',
    'lg': '1024px',
    'lgp': '1200px',
    'ml': '1244px',
    'xl': '1280px',
    'tablet': '1281px',
    '2xl': '1440px',
    '2xml': '1470px',
    '2ll': '1560px',
    '3xl': '1680px',
    '4xl': '1790px',
    'full': '1920px',
  },

  borderRadius: {
    'none': '0',
    'sm': '0.125rem',
    'base': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  },
  colors: {
    ...BasePalleteDark,
    ...BluePallete,
  },
  fonts: {
    stabil_grotesk_light: 'StabilGrotesk-Light, sans serif',
    stabil_grotesk_medium: 'StabilGrotesk-Medium, sans serif',
    stabil_grotesk_regular: 'StabilGrotesk-Regular, sans serif',
    inter_bold: 'Inter-Bold, sans serif',
    inter_light: 'Inter-Light, sans serif',
    inter_medium: 'Inter-Medium, sans serif',
    inter_regular: 'Inter-Regular, sans serif',
    inter_semibold: 'Inter-SemiBold, sans serif',
  },
  fontSizes: {
    'xxs': '9px',
    'xs': '10px',
    's': '12px',
    'x-small': '12px',
    'sm': '14px',
    'md': '16px',
    'lg': '18px',
    'lg-bigger': '20px',
    'xl': '22px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '48px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  shadows: {
    shadow0: 'rgba(222, 224, 232, 0.5) 0 0 12px 0 ',
    shadow6: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  transitions: {
    xs: '0.2s',
    sm: '0.3s',
    md: '0.5s',
    lg: '1s',
    xl: '1.5s',
  },
};

export const greenTheme = {
  name: {
    id: 'green',
    testId: 'accentGreen',
  },

  breakPoints: {
    'xxs': '280px',
    'xs': '320px',
    'sm': '375px',
    'sml': '500px',
    'md': '768px',
    'lg': '1024px',
    'lgp': '1200px',
    'ml': '1244px',
    'xl': '1280px',
    'tablet': '1281px',
    '2xl': '1440px',
    '2xml': '1470px',
    '2ll': '1560px',
    '3xl': '1680px',
    '4xl': '1790px',
    'full': '1920px',
  },

  borderRadius: {
    'none': '0',
    'sm': '0.125rem',
    'base': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  },
  colors: {
    ...BasePalleteDark,
    ...GreenPallete,
  },
  fonts: {
    stabil_grotesk_light: 'StabilGrotesk-Light, sans serif',
    stabil_grotesk_medium: 'StabilGrotesk-Medium, sans serif',
    stabil_grotesk_regular: 'StabilGrotesk-Regular, sans serif',
    inter_bold: 'Inter-Bold, sans serif',
    inter_light: 'Inter-Light, sans serif',
    inter_medium: 'Inter-Medium, sans serif',
    inter_regular: 'Inter-Regular, sans serif',
    inter_semibold: 'Inter-SemiBold, sans serif',
  },
  fontSizes: {
    'xxs': '9px',
    'xs': '10px',
    's': '12px',
    'x-small': '12px',
    'sm': '14px',
    'md': '16px',
    'lg': '18px',
    'lg-bigger': '20px',
    'xl': '22px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '48px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  shadows: {
    shadow0: 'rgba(222, 224, 232, 0.5) 0 0 12px 0 ',
    shadow6: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  transitions: {
    xs: '0.2s',
    sm: '0.3s',
    md: '0.5s',
    lg: '1s',
    xl: '1.5s',
  },
};

export const yellowTheme = {
  name: {
    id: 'yellow',
    testId: 'accentYellow',
  },

  breakPoints: {
    'xxs': '280px',
    'xs': '320px',
    'sm': '375px',
    'sml': '500px',
    'md': '768px',
    'lg': '1024px',
    'lgp': '1200px',
    'ml': '1244px',
    'xl': '1280px',
    'tablet': '1281px',
    '2xl': '1440px',
    '2xml': '1470px',
    '2ll': '1560px',
    '3xl': '1680px',
    '4xl': '1790px',
    'full': '1920px',
  },

  borderRadius: {
    'none': '0',
    'sm': '0.125rem',
    'base': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  },
  colors: {
    ...BasePalleteDark,
    ...YellowPallete,
  },
  fonts: {
    stabil_grotesk_light: 'StabilGrotesk-Light, sans serif',
    stabil_grotesk_medium: 'StabilGrotesk-Medium, sans serif',
    stabil_grotesk_regular: 'StabilGrotesk-Regular, sans serif',
    inter_bold: 'Inter-Bold, sans serif',
    inter_light: 'Inter-Light, sans serif',
    inter_medium: 'Inter-Medium, sans serif',
    inter_regular: 'Inter-Regular, sans serif',
    inter_semibold: 'Inter-SemiBold, sans serif',
  },
  fontSizes: {
    'xxs': '9px',
    'xs': '10px',
    's': '12px',
    'x-small': '12px',
    'sm': '14px',
    'md': '16px',
    'lg': '18px',
    'lg-bigger': '20px',
    'xl': '22px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '48px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  shadows: {
    shadow0: 'rgba(222, 224, 232, 0.5) 0 0 12px 0 ',
    shadow6: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  transitions: {
    xs: '0.2s',
    sm: '0.3s',
    md: '0.5s',
    lg: '1s',
    xl: '1.5s',
  },
};

export const pinkTheme = {
  name: {
    id: 'pink',
    testId: 'accentPink',
  },

  breakPoints: {
    'xxs': '280px',
    'xs': '320px',
    'sm': '375px',
    'sml': '500px',
    'md': '768px',
    'lg': '1024px',
    'lgp': '1200px',
    'ml': '1244px',
    'xl': '1280px',
    'tablet': '1281px',
    '2xl': '1440px',
    '2xml': '1470px',
    '2ll': '1560px',
    '3xl': '1680px',
    '4xl': '1790px',
    'full': '1920px',
  },

  borderRadius: {
    'none': '0',
    'sm': '0.125rem',
    'base': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  },
  colors: {
    ...BasePalleteDark,
    ...PinkPallete,
  },
  fonts: {
    stabil_grotesk_light: 'StabilGrotesk-Light, sans serif',
    stabil_grotesk_medium: 'StabilGrotesk-Medium, sans serif',
    stabil_grotesk_regular: 'StabilGrotesk-Regular, sans serif',
    inter_bold: 'Inter-Bold, sans serif',
    inter_light: 'Inter-Light, sans serif',
    inter_medium: 'Inter-Medium, sans serif',
    inter_regular: 'Inter-Regular, sans serif',
    inter_semibold: 'Inter-SemiBold, sans serif',
  },
  fontSizes: {
    'xxs': '9px',
    'xs': '10px',
    's': '12px',
    'x-small': '12px',
    'sm': '14px',
    'md': '16px',
    'lg': '18px',
    'lg-bigger': '20px',
    'xl': '22px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '48px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  shadows: {
    shadow0: 'rgba(222, 224, 232, 0.5) 0 0 12px 0 ',
    shadow6: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  transitions: {
    xs: '0.2s',
    sm: '0.3s',
    md: '0.5s',
    lg: '1s',
    xl: '1.5s',
  },
};

export const whiteTheme = {
  name: {
    id: 'white',
    testId: 'accentWhite',
  },

  breakPoints: {
    'xxs': '280px',
    'xs': '320px',
    'sm': '375px',
    'sml': '500px',
    'md': '768px',
    'lg': '1024px',
    'lgp': '1200px',
    'ml': '1244px',
    'xl': '1280px',
    'tablet': '1281px',
    '2xl': '1440px',
    '2xml': '1470px',
    '2ll': '1560px',
    '3xl': '1680px',
    '4xl': '1790px',
    'full': '1920px',
  },

  borderRadius: {
    'none': '0',
    'sm': '0.125rem',
    'base': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  },
  colors: {
    ...BasePalleteDark,
    ...WhitePallete,
  },
  fonts: {
    stabil_grotesk_light: 'StabilGrotesk-Light, sans serif',
    stabil_grotesk_medium: 'StabilGrotesk-Medium, sans serif',
    stabil_grotesk_regular: 'StabilGrotesk-Regular, sans serif',
    inter_bold: 'Inter-Bold, sans serif',
    inter_light: 'Inter-Light, sans serif',
    inter_medium: 'Inter-Medium, sans serif',
    inter_regular: 'Inter-Regular, sans serif',
    inter_semibold: 'Inter-SemiBold, sans serif',
  },
  fontSizes: {
    'xxs': '9px',
    'xs': '10px',
    's': '12px',
    'x-small': '12px',
    'sm': '14px',
    'md': '16px',
    'lg': '18px',
    'lg-bigger': '20px',
    'xl': '22px',
    '2xl': '24px',
    '3xl': '28px',
    '4xl': '32px',
    '5xl': '40px',
    '6xl': '48px',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  shadows: {
    shadow0: 'rgba(222, 224, 232, 0.5) 0 0 12px 0 ',
    shadow6: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  },
  transitions: {
    xs: '0.2s',
    sm: '0.3s',
    md: '0.5s',
    lg: '1s',
    xl: '1.5s',
  },
};

export default theme;

import { Button } from 'antd';
import styled from 'styled-components';

type ButtonTextProps = {
  isDisabled?: boolean;
};

export const HandoffButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
`;

export const HandoffOptionButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.handoffButton};
  border: 0px;
  border-radius: 6px;
  height: 50px;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px 32px 10px 32px;
  &:hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.handoffButton};
  }
  &:disabled {
    background-color: ${({ theme }) =>
      theme.colors.handoffRequestButtonDisabled};
    color: ${({ theme }) => theme.colors.handoffRequestButtonDisabledText};
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.handoffRequestButtonDisabled};
      color: ${({ theme }) => theme.colors.handoffRequestButtonDisabledText};
    }
  }
`;

export const ButtonText = styled.span<ButtonTextProps>`
  color: ${({ isDisabled, theme }) =>
    isDisabled
      ? theme.colors.handoffRequestButtonDisabledText
      : theme.colors.handoffRequestButtonText};
  font-family: ${({ theme }) => theme.fonts.inter_semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
  float: left;
`;

export const ButtonSubText = styled.span`
  color: ${({ theme }) => theme.colors.handoffRequestButtonSubtext};
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  font-size: ${({ theme }) => theme.fontSizes.s};
  float: right;
  margin-top: 2px;
`;

export const LanguageHandoffTimer = styled.span`
  color: ${({ theme }) => theme.colors.primaryColorBase};
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_medium};
  font-size: 48px;
  position: relative;
  top: 75px;
  display: flex;
  justify-content: center;
  width: 448px;
`;

export const CancelHandoffButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.handoffCancelButton};
  border: 0px;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.handoffCancelButtonText};
  font-family: ${({ theme }) => theme.fonts.inter_semibold};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  height: 44px;
  width: 293px;
  padding: 10px 32px 10px 32px;
  &:hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.handoffCancelButton};
    color: ${({ theme }) => theme.colors.handoffCancelButtonText};
  }
`;

export const HandoffContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HandoffParagraphText = styled.p`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_regular};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  text-align: center;
  color: ${({ theme }) => theme.colors.whiteFontColor};
  height: 166px;
  text-align: center;
  align-items: center;
`;

export const LanguageHandoffParagraphText = styled.p`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_regular};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  text-align: center;
  color: ${({ theme }) => theme.colors.whiteFontColor};
`;

export const HandoffConfirmButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border: 0px;
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.handoffNextCAButtonEnabledText};
  font-family: ${({ theme }) => theme.fonts.inter_semibold};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  height: 44px;
  width: 293px;
  padding: 10px 32px 10px 32px;
  &:hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    color: ${({ theme }) => theme.colors.handoffNextCAButtonEnabledText};
  }
  &:disabled {
    background-color: ${({ theme }) =>
      theme.colors.handoffRequestButtonDisabled};
    color: ${({ theme }) => theme.colors.handoffRequestButtonDisabledText};
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.handoffRequestButtonDisabled};
      color: ${({ theme }) => theme.colors.handoffRequestButtonDisabledText};
    }
  }
`;

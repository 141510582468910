import { AuthenticationContext } from 'modules/authentication/authentication.context';
import React from 'react';
import { agentQueueService } from 'shared/config/rest.config';
import { AQAddAgentInput } from 'shared/datasources/agent-queue-service/entities.dto';

const usePostAddAgentQueue = () => {
  const { getUserToken } = React.useContext(AuthenticationContext);
  const [
    {
      data: dataPostAgentQueue,
      loading: loadingPostAgentQueue,
      error: errorPostAgentQueue,
    },
    post,
  ] = agentQueueService<AQAddAgentInput>(
    {
      url: '/add-agent',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  return {
    post,
    dataPostAgentQueue,
    loadingPostAgentQueue,
    errorPostAgentQueue,
  };
};

export default usePostAddAgentQueue;

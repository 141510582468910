export enum BasePalleteLight {
  answeringMachineModeTagColor = '#609bf0',
  audioOptionColor = '#949696',
  backgroundColor911 = '#FF695C21',
  borderColor = '#95C940',
  borderColor911 = '#FF695C',
  callControlsBody = '#FFFFFF',
  callControlsHeader = '#51DBCD21',
  callControlsTab = '#F5F5F5',
  callControlsTabSelected = '#95C940',
  callControlsTabSelectedText = '#242726',
  callControlsTabText = '#626262',
  callControlsHeaderText = '#000000E3',
  callControlsHeaderTextPFC = '#95C940',
  whiteFontColor = '#FFFFFF',
  whiteBackground = '#FFFFFF',
  blackBackground = '#000000',
  modalFontColor = '#FFFFFF',
  colorText = '#24292e',
  queue911FontColor = '#24292e',
  fontColor = '#24292e',
  backgroundColor = '#24292e',
  cancelColor = '#FF695C',
  dispositionSVG = '#E5E6E6',
  dispositionSVGDisabled = '#E5E6E6',
  handoffButton = '#0408090D',
  handoffCancelButton = '#FF695C21',
  handoffCancelButtonText = '#FF695C',
  handoffNextCAButtonEnabled = '#51DBCD',
  handoffNextCAButtonEnabledText = '#000000',
  handoffRequestButtonDisabled = '#111416',
  handoffRequestButtonDisabledText = '#808080',
  handoffRequestButtonSubtext = '#000000',
  handoffRequestButtonText = '#000000',
  iconColor = '#72F7E9',
  iconColorTwo = '#95C940',
  mainBackground = '#FFFFFF',
  muteButtonBackgroundColor = 'rgba(255, 255, 255, 0.13)',
  primaryColorBase = '#09a7e0',
  chronoColor = '#09a7e0',
  sessionBackground = '#f2f4f7',
  sessionEditorLowerBackground = 'rgba(4, 8, 9, 0.05)',
  sliderRail = '#ffffff14',
  sliderTrack = '#00a297',
  testButtonBackground = '#626262',
  blueThemeColor = '#78ADF9',
  pinkThemeColor = '#F39BDF',
  greenThemeColor = '#95C940',
  whiteThemeColor = '#FFFFFF',
  yellowThemeColor = '#FFCF87',
}

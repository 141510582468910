import * as React from 'react';

import * as Styled from './loader.styled';

interface ILoaderProps {
  durationInSeconds: number;
  type: string;
}

const Loader = ({ durationInSeconds, type }: ILoaderProps) => {
  const loader = React.useMemo(
    () => (
      <>
        {type === 'big-loader' ? (
          <Styled.BigLoader seconds={durationInSeconds} />
        ) : (
          <Styled.SmallLoader seconds={durationInSeconds} />
        )}
      </>
    ),
    []
  );
  return loader;
};

export default Loader;

import * as React from 'react';

import RoutesModule from '../routes.module';
import { IRouteModule } from '../routes.types';

type RouteModuleContextType = [IRouteModule[]];

const RouteModuleContext = React.createContext(
  [] as any as RouteModuleContextType
);

const RouteModuleProvider: React.FC = ({ children }) => {
  const routeModules = React.useMemo<IRouteModule[]>(() => RoutesModule(), []);
  return (
    <RouteModuleContext.Provider value={[routeModules]}>
      {children}
    </RouteModuleContext.Provider>
  );
};

export { RouteModuleContext, RouteModuleProvider };

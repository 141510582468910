import { AxiosPromise } from 'axios';
import { useAuthService } from 'shared/config/rest.config';

type ReturnValue<T> = {
  getUser: (accessToken: string) => AxiosPromise<T>;
  authService: [T | undefined, boolean, any];
};

const useAuthUser = <K>(): ReturnValue<K> => {
  const [
    {
      data: dataPostAgentService,
      loading: loadingPosAgentService,
      error: errorPostAgentService,
    },
    get,
  ] = useAuthService<K>(
    {
      url: 'auth/user',
      method: 'GET',
    },
    {
      manual: true,
      autoCancel: false,
    }
  );
  const getUser = (accessToken: string) =>
    get({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  return {
    getUser,
    authService: [
      dataPostAgentService,
      loadingPosAgentService,
      errorPostAgentService,
    ],
  };
};

export default useAuthUser;

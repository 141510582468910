import { useCallback, useRef } from 'react';
import { GatewayEvent } from 'shared/hooks/axon/ipcts-gw/gateway-events.enum';
import { demoLog } from 'shared/utils/demo-log';

export interface ISocketFactory {
  wsUrl: string;
  handleIncommingAudio?: (binaryData: Blob) => void;
}

type GwMessage = {
  event: string;
  payload: any;
};

export const useSocketFactory = ({
  wsUrl,
  handleIncommingAudio,
}: ISocketFactory) => {
  const socket = useRef<WebSocket | null>(null);
  const eventHandlers = useRef<Record<string, (value?: any) => any> | null>(
    null
  );
  const setEventsHandlers = (hadlers: Record<string, (value?: any) => any>) => {
    eventHandlers.current = hadlers;
  };
  const handleMsg = (msg: GwMessage) => {
    if (!eventHandlers.current) {
      demoLog('Error: No Events Loaded in Socket');
      return;
    }
    const handler = eventHandlers.current[msg.event];
    if (!handler) {
      demoLog(`Error: Handler not found for this event: ${msg.event}`);
      return;
    }
    handler(msg);
  };

  const send = (message: string | ArrayBuffer) => {
    if (socket.current != null) {
      socket.current.send(message);
    }
  };

  const openSocket = useCallback(async () => {
    const url = new URL(wsUrl);
    socket.current = new WebSocket(url);
    socket.current.onerror = (e) => {
      demoLog('could not connect to: ', url, e);
    };
    socket.current.onopen = () => {
      demoLog('opened socket connection to: ', url);
    };
    socket.current.onclose = (e) => {
      demoLog('Socket Connection closed: ', e);
      handleMsg({ event: GatewayEvent.connectionClosed, payload: e });
    };
    socket.current.onmessage = (e: MessageEvent) => {
      if (e.data instanceof Blob && handleIncommingAudio) {
        handleIncommingAudio(e.data);
      } else {
        const msg = JSON.parse(e.data);
        handleMsg(msg);
      }
    };
  }, [handleMsg]);

  const closeSocket = () => {
    if (!socket.current) return;
    socket.current!.close();
    socket.current = null;
  };

  return () => ({
    send,
    openSocket,
    closeSocket,
    setEventsHandlers,
  });
};

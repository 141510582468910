export enum BasePallete {
  //primary Colors
  primaryColorBase = '#24292e',
  primaryColorBaseHover = '#048ebf',
  primaryColorBasePressed = '#03b4f4',
  outlinedColorText = '#06a7e0',
  outlinedFocusBackground = '#48a6db',
  white = '#ffffff', // NOT A GENERIC NAME
  blackOne = '#ffffff', // NOT A GENERIC NAME
  blackTwo = '#f2f4f7', // NOT A GENERIC NAME
  blackThree = '#24292e',
  blackPearl = '#080f12',
  activeState = '#8ad6d4',
  destructiveRestingRed = '#ff695c',
  //Secondary Colors

  gray = '#747474',
  negativeTeal = '#008079',
  ipctsBackgroundGray = '#FAFAFA',
  ipctsBackgroundTransparency = 'rgba(4, 8, 9, 0.04)',
  activeActionsColor = '#008079',
  backgroundGray = '#e3e3e5',
  paleGray = '#cce6e7',
  porpoiseFin = '#c7c9cc',
  darkerGray = '#f2f4f7', // NOT A GENERIC NAME (REPEATED1)
  secondaryColorBase = '#404547', // MODIFIED
  tertiaryColorBase = '#33b0a6',
  bianchiGreen = '#3dcdc2',
  socasiTeal400 = '#61d6cc', // ADDED
  mountainLakeBlue = '#8ad6d4',
  skylar = '#6acac1',
  //Tertiary Colors
  error = '#e25241',
  warning = '#faad14',
  success = '#53c31b',
  info = '#1990ff',
  //GrayScale Colors
  lighterGray = '#ebebeb',
  disabled = '#888b8d',
  outlinedDisabledColor = '#b7b7b7',
  iconColor = '#717171',
  midnightBlue300 = '#adb0b0', // ADDED
  midnightBlue400 = '#919496', // ADDED
  midnightBlue500 = '#757a7d', // ADDED
  midnightBlue600 = '#595e61', // ADDED
  //RGB Colors
  whiteRGBA = 'rgba(0,0,0,{opacity})',
  activeStateRGBA = 'rgba(138, 214, 212, {opacity})',
  backgroundColorDisabled = '#f5f5f5',
  descriptionColor = '#a6a6a6', // UNUSED
  breadcrumbColor = '#bcbaba', // UNUSED
  filtersBackground = '#f2f4f7', // MODIFIED (REPEATED1)
  filtersTextColor = '#a5a5a5',
  borderColorGray = '#707070',
  //
  //
  //
  //
  primaryColorBaseLighter = '#5194AF',
  primaryColorBaseRGBA = 'rgba(2,124,158,{opacity})',
  primaryColorComplement = '#212427',
  textColorBase = '#343A40',
  textColorBaseRGBA = 'rgba(52,58,64,{opacity})',
  textColorComplement = '#000',
  inactiveGrey = 'rgba(0, 0, 0, 0.2)',
  background = '#ffffff', // MODIFIED
  insideBackground = '#383A3C',
  disabled2 = '#e3e4e4',
  theadBackground = '#B2D7E2',
  theadBackgroundRGBA = 'rgba(178,215,226,{opacity})',
  rowTablesHover = '#EFF6FC',
  lineTables = '#36A0EB',
  inputsBorder = '#E5E8EB',
  black = '#000',
  grayRGBA = 'rgba(128, 137, 146, {opacity})',
  lightGray = '#BBBBBB',
  nobel = '#B4B4B4',
  blue = '#007BFF',
  blueRGBA = 'rgba(0,123,255,{opacity})',
  havelockBlue = '#48a7dc',
  red = '#DC3545',
  redRGBA = 'rgba(220, 53, 69, {opacity})',
  yellow = '#F4BF55',
  green = '#48B538',
  lightBlue = '#387FF0',
  transparent = 'transparent',
  dropdownGray = '#f2f2f2',
  titleGray = '#353a3f',
  redPlatform = '#dc3545',
  deepSkyBlue = '#007bff',
  placeholderGray = '#808992',
  blackRGBA = 'rgba(0,0,0,{opacity})',
  textNotification = '#222222',
  successNotification = '#f6ffec',
  successBorderNotification = '#53c31b',
  errorNotification = '#fff2f0',
  errorBorderNotification = '#ed7773',
  warningNotification = '#FEFBE6',
  warningBorderNotification = '#FFE58F',
  infoNotification = '#e6f6ff',
  infoBorderNotification = '#48a7dc',
  yellowRGBA = 'rgba(245,191,80,{opacity})',
  borderColor = '#232323',
  errorText = '#5a0d14',
  backgroundPlatform = 'rgba(220, 53, 69, 0.1)',
  popUpBorder = '#aeb0b0',
  alternativesHighlight = '#cc5500',
}

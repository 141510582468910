import { Typography } from 'antd';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 725px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.mainBackground} !important;

  margin-bottom: 168px;
  border-radius: 5px;
`;

export const IconContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px;
  gap: 32px;
  width: 118px;
  height: 118px;
  border: 2px dashed ${({ theme }) => theme.colors.borderColor} !important;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  margin-bottom: 28px;
`;

export const Icon = styled(SVG)`
  height: 56px;
  width: 56px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  path {
    fill: ${({ theme }) => theme.colors.iconColorTwo} !important;
  }
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.inter_bold};
  font-size: ${({ theme }) => theme.fontSizes['3xl']};
  margin-bottom: 32px;
  .ant-skeleton {
    .ant-skeleton-content {
      h3 {
        height: 28px !important;
        margin: 0;
      }
    }
  }
  @media (max-width: 1279px) {
    padding: 0px;
    width: 100%;
  }
`;

export const Title = styled(Typography.Title)`
  font-size: ${({ theme }) => theme.fontSizes['2xl']} !important;
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_medium} !important;
  font-weight: ${({ theme }) => theme.fontWeights.medium} !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primaryColorBase} !important;
  margin-left: 8px;
  margin-bottom: 0px !important;

  .ant-typography-expand {
    color: ${({ theme }) => theme.colors.activeState};
  }
  .ant-typography a:hover,
  .ant-typography-expand:hover {
    color: ${({ theme }) =>
      theme.colors.activeStateRGBA?.replace('{opacity}', '0.6')};
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
    font-size: ${({ theme }) => theme.fontSizes['2xl']};
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }
`;

export const HandOffCompletedText = styled.div`
  font-family: ${({ theme }) => theme.fonts.inter_bold};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.fontColor} !important;
  line-height: 36px;
  margin-top: 28px;
  margin-bottom: 8px;
`;

export const DescriptionText = styled.div`
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 19px;
  color: ${({ theme }) => theme.colors.fontColor} !important;
`;

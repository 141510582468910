import EXITING from 'assets/icons/handoff-exiting.svg';
import REENTER from 'assets/icons/handoff-reenter.svg';
import React, { useCallback } from 'react';
import * as Styled from 'shared/components/ui/handoff-screen/handoff-screen.styled';

interface HandOffScreen {
  isLanguageHandOff?: boolean;
}

const HandoffScreen = (props: HandOffScreen) => {
  const { isLanguageHandOff } = props;

  const showMessage = useCallback(() => {
    if (isLanguageHandOff) {
      return (
        <>
          <Styled.IconContainer>
            <div>
              <Styled.Icon src={REENTER} />
            </div>
          </Styled.IconContainer>
          <Styled.HandOffCompletedText>
            Handoff has been Completed.
          </Styled.HandOffCompletedText>
          <Styled.DescriptionText>Entering to queue...</Styled.DescriptionText>
        </>
      );
    }

    return (
      <>
        <Styled.IconContainer>
          <div>
            <Styled.Icon src={EXITING} />
          </div>
        </Styled.IconContainer>
        <Styled.HandOffCompletedText>
          Handoff has been Completed.
        </Styled.HandOffCompletedText>
        <Styled.DescriptionText>Exiting...</Styled.DescriptionText>
      </>
    );
  }, [isLanguageHandOff]);

  return (
    <>
      <Styled.TextContainer>{showMessage()}</Styled.TextContainer>
    </>
  );
};

export default HandoffScreen;

import { AuthenticationContext } from 'modules/authentication/authentication.context';
import { useContext } from 'react';
import { config } from 'shared/config/cts.config';

import packageJson from '../../../../package.json';

const appVersion = packageJson.version;

export enum LogLevelType {
  INFO = 'info',
  ERROR = 'error',
  WARN = 'warn',
}
export type DynatraceLogsType = {
  logLevelType: LogLevelType;
  callId?: string;
  agentNumber?: string;
  dateTime?: string;
  methodName?: string;
  parameters?: Record<string, any>;
  AppName?: string;
  AppVersion?: string;
  region?: string;
  message?: string;
  errorStack?: string;
};

async function postLog(userToken: string, logData: DynatraceLogsType) {
  let response;
  try {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', `Bearer ${userToken}`);

    response = await fetch(`${config.REACT_APP_CAPTIONER_SERVICES_API}/logs`, {
      method: 'POST',
      headers: requestHeaders,
      mode: 'cors',
      body: JSON.stringify(logData),
    });
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error({
      logLevelType: LogLevelType.ERROR,
      methodName: 'postLog',
      parameters: { logData },
      message: `Failed with error message ${error.message} status code ${response?.status}`,
      errorStack: error.stack,
    });
  }
}
function buildLog(logData: DynatraceLogsType) {
  return {
    logLevelType: logData.logLevelType,
    callId: logData.callId,
    agentNumber: logData.agentNumber,
    dateTime: new Date().toISOString(),
    methodName: logData.methodName,
    parameters: logData.parameters,
    AppName: 'Captioner',
    AppVersion: appVersion,
    region: process.env.REACT_APP_AWS_REGION,
    message: logData.message,
    errorStack: logData.errorStack,
  };
}

export const usePostDynatraceLogs = () => {
  const { getUserToken } = useContext(AuthenticationContext);

  const log = async (logs: DynatraceLogsType) => {
    const logData = buildLog(logs);

    // eslint-disable-next-line no-console
    console[logs.logLevelType](logData);
    return postLog(getUserToken() as string, logData);
  };

  return {
    log,
  };
};

export const usePostDynatraceLogsNoContext = () => {
  const log = async (userToken: string, logs: DynatraceLogsType) => {
    const logData = buildLog(logs);
    // eslint-disable-next-line no-console
    console[logs.logLevelType](logData);
    return postLog(userToken, logData);
  };
  return {
    log,
  };
};

import { isNull } from 'lodash';
import {
  AuthenticationContext,
  User,
} from 'modules/authentication/authentication.context';
import * as React from 'react';

export const AgentAccessControl: React.FC = ({ children }) => {
  const { user } = React.useContext(AuthenticationContext);
  const [showPage, setShowPage] = React.useState(false);

  const isUserAllow = (user: User | null) => {
    if (isNull(user)) return;
    setShowPage(true);
  };

  React.useEffect(() => {
    isUserAllow(user);
  }, []);

  return <>{showPage && children}</>;
};

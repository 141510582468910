import { Button, Radio, Select } from 'antd';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const TypeOfCallContainer = styled.div`
  width: 448px;
`;

export const PartyModeTitleContainer = styled.div`
  height: 23px;
  width: 100%;
  text-align: center;
  margin-bottom: 18px;
  margin-top: 19px;
`;

export const PartyModeTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter_semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.whiteFontColor};
`;

export const DescriptionText = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  display: flex;
  padding-top: 16px;
  padding-left: 24px;
  padding-bottom: 16px;
`;

export const TypeButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  height: 96px;
  width: 96px;
  padding: 10px 6px 14px;
  &:hover,
  :focus {
    border-color: ${({ theme }) => theme.colors.tertiaryColorBase};
  }
  &:nth-child(2) {
    margin-left: 36px;
  }
  &:nth-child(3) {
    margin-left: 45px;
  }
  &:nth-child(4) {
    margin-left: 45px;
  }
  &:nth-child(5) {
    margin-top: 36px;
    margin-left: 36px;
  }
  &:nth-child(6) {
    margin-left: 45px;
  }
`;

export const RadioInput = styled(Radio)`
  height: 16px;
  width: 16px;
  button > & {
    padding-right: 62px;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.tertiaryColorBase} !important ;
  }
  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${({ theme }) => theme.colors.tertiaryColorBase};
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.tertiaryColorBase};
  }
`;

export const IconSVG = styled(SVG)`
  cursor: pointer;
  margin-left: auto;
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.colors.secondaryColorBase};
`;

type SVGType = {
  isOpen: boolean;
};

export const SilentIconSVG = styled(IconSVG)<SVGType>`
  margin: 0;
  path {
    fill: ${({ theme, isOpen }) =>
      isOpen
        ? theme.colors.dispositionSVGDisabled
        : theme.colors.dispositionSVGDisabled};
  }
`;

export const OtherIconSVG = styled(IconSVG)<SVGType>`
  margin: 0;
  path {
    fill: ${({ theme, isOpen }) =>
      isOpen
        ? theme.colors.dispositionSVG
        : theme.colors.dispositionSVGDisabled};
  }
`;

type ButtonTextProps = {
  isDisabled?: boolean;
};

export const DispositionButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
`;

export const DispositionOptionButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.handoffButton};
  border: 0px;
  border-radius: 6px;
  height: 50px;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px 32px 10px 32px;
  &:hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.handoffButton};
    border: 0px;
  }
  &:disabled {
    background-color: ${({ theme }) =>
      theme.colors.handoffRequestButtonDisabled};
    color: ${({ theme }) => theme.colors.handoffRequestButtonDisabledText};
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.handoffRequestButtonDisabled};
      color: ${({ theme }) => theme.colors.handoffRequestButtonDisabledText};
    }
  }
`;

export const ButtonText = styled.span<ButtonTextProps>`
  color: ${({ isDisabled, theme }) =>
    isDisabled
      ? theme.colors.handoffRequestButtonDisabledText
      : theme.colors.handoffRequestButtonText};
  font-family: ${({ theme }) => theme.fonts.inter_semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const AnsweringMachineModeTag = styled.div`
  color: black;
  background-color: ${({ theme }) => theme.colors.answeringMachineModeTagColor};
  height: 41px;
  width: 100%;
  text-align: center;
  padding: 9px 0;
  margin-bottom: 19px;
`;

export const AnsweringMachineModeText = styled.span`
  font-family: ${({ theme }) => theme.fonts.inter_semibold};
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const SVGIcon = styled(SVG)`
  height: 24px;
  width: 24px;
  path {
    fill: #ffffffd6 !important;
  }
`;

export const SelectDropDown = styled(Select)`
  .ant-select-selector {
    background-color: transparent !important;
    font-family: ${({ theme }) => theme.fonts.inter_semibold};
    font-size: ${({ theme }) => theme.fontSizes.md};
    color: ${({ theme }) => theme.colors.handoffRequestButtonText};
    padding-left: 0px !important;
  }
  .ant-select-selection-item {
    text-align: left;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.handoffRequestButtonText};
  }

  .ant-select-dropdown {
    width: 323px;
  }
`;

//===============================

export const DispositionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 48px;
`;

export const DispositionOption = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.handoffButton};
  border: 0px;
  border-radius: 6px;
  height: 50px;
  width: 100%;
  margin-bottom: 16px;
  padding: 10px 32px 10px 32px;
  &:hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.handoffButton};
    border: 0px;
  }
  &:disabled {
    background-color: ${({ theme }) =>
      theme.colors.handoffRequestButtonDisabled};
    color: ${({ theme }) => theme.colors.handoffRequestButtonDisabledText};
    &:hover {
      background-color: ${({ theme }) =>
        theme.colors.handoffRequestButtonDisabled};
      color: ${({ theme }) => theme.colors.handoffRequestButtonDisabledText};
    }
  }
`;

import { LDProvider } from 'launchdarkly-react-client-sdk';
import { AppConfigContextProvider } from 'modules/AppConfig/context';
import { AgentAccessControl } from 'modules/authentication/agentAccessControl';
import { AuthenticationProvider } from 'modules/authentication/authentication.context';
import { OAuthProvider } from 'modules/authentication/oauth.context';
import AppRouterComponent from 'modules/routing/app-router.component';
import { CurrentRouteProvider } from 'modules/routing/context/current-route.context';
import { RouteModuleProvider } from 'modules/routing/context/route-model.context';
import { ThemeProvider } from 'modules/theme/theme.context';
import React, { useEffect } from 'react';
import Layout from 'shared/components/layout/layout.component';
import { config } from 'shared/config/cts.config';
import { UserStates } from 'shared/hooks/admin/states.enum';
import { useUserStateNoContext } from 'shared/hooks/admin/use-post-update-user-state.hook';
import { useNetworkState } from 'shared/hooks/use-network-state';
import {
  useStateStorage,
  LocalStorageKeys,
  TokensInfo,
  TokenUser,
} from 'shared/hooks/use-state-storage.hook';
import GlobalStyle from 'shared/styles/global-styles';

declare global {
  interface Window {
    electronAPI: any;
  }
}

function App() {
  useNetworkState();
  const [tokens] = useStateStorage<TokensInfo>(LocalStorageKeys.tokens, {
    accessToken: '',
    refreshToken: '',
  });
  const [tokenUser] = useStateStorage<TokenUser | null>(
    LocalStorageKeys.user,
    null
  );

  const { updateUserState } = useUserStateNoContext();
  useEffect(() => {
    if (window?.electronAPI?.on) {
      window?.electronAPI.on('close-app', async () => {
        if (tokens.accessToken && tokenUser) {
          await updateUserState(tokens.accessToken, {
            stateName: UserStates.OFFLINE,
            sessionId: tokenUser?.jti as string,
          });
        }
        window?.electronAPI?.closeApp(false);
      });
      return () => {};
    } else {
      // Only do this if we are NOT running in electron
      const unloadCallback = () => {
        // beforeunload will be triggered on refresh in which case userActivation?.isActive will be true
        // so make sure it is false before sending UserStates.OFFLINE
        if (tokens.accessToken && !window.navigator?.userActivation?.isActive) {
          updateUserState(tokens.accessToken, {
            stateName: UserStates.OFFLINE,
            sessionId: tokenUser?.jti as string,
          });
        }
      };

      window.addEventListener('beforeunload', unloadCallback);
      return () => window.removeEventListener('beforeunload', unloadCallback);
    }
  }, []);

  return (
    <LDProvider
      clientSideID={`${config.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID}`}
    >
      <OAuthProvider>
        <AuthenticationProvider>
          <AppConfigContextProvider>
            <GlobalStyle />
            <RouteModuleProvider>
              <ThemeProvider>
                <CurrentRouteProvider>
                  <Layout>
                    <AgentAccessControl>
                      <AppRouterComponent />
                    </AgentAccessControl>
                  </Layout>
                </CurrentRouteProvider>
              </ThemeProvider>
            </RouteModuleProvider>
          </AppConfigContextProvider>
        </AuthenticationProvider>
      </OAuthProvider>
    </LDProvider>
  );
}

export default App;

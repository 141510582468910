import { ICurrentRoute } from 'modules/routing/routes.types';
import * as React from 'react';

type CurrentRouteContextType = [
  ICurrentRoute,
  React.Dispatch<React.SetStateAction<ICurrentRoute>>
];

const CurrentRouteContext = React.createContext(
  [] as any as CurrentRouteContextType
);

const CurrentRouteProvider: React.FC = ({ children }) => {
  const stateDispatch = React.useState<ICurrentRoute>({
    showNavBar: false,
    showSideBar: false,
    path: [''],
  });

  return (
    <CurrentRouteContext.Provider value={stateDispatch}>
      {children}
    </CurrentRouteContext.Provider>
  );
};

export { CurrentRouteContext, CurrentRouteProvider };

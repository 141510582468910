import { Duration } from 'luxon';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';

export const useChronometer = (autoStart: boolean = false) => {
  const [counter, setCounter] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);

  const time = React.useRef({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [initialTime, setInitialTime] = useState<Moment>(moment(new Date()));

  const resetTimer = () => {
    setInitialTime(moment(new Date()));
    time.current = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  };

  React.useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isActive || autoStart) {
      const tempDate = moment(new Date());

      intervalId = setInterval(() => {
        const {
          hours = 0,
          minutes = 0,
          seconds = 0,
        } = Duration.fromMillis(tempDate.diff(initialTime))
          .shiftTo('hours', 'minutes', 'seconds')
          .toObject();

        time.current = {
          hours,
          minutes,
          seconds: Math.floor(seconds),
        };

        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [counter, isActive]);

  return {
    counter,
    resetTimer,
    time: time.current,
    setIsActive,
  };
};

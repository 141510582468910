import { Tooltip, Typography, Button } from 'antd';
import SVG from 'react-inlinesvg';
import { Chronometer } from 'shared/components/ui/queue-screen/components/chronometer/chronometer.component';
import styled from 'styled-components';

export const QueueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  height: 725px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.mainBackground} !important;
  border-radius: 5px;
`;

export const Background = styled(SVG)`
  position: fixed;
  margin-top: -300px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;
export const LeaveButton = styled(Button)`
  padding: 14px 0;
  justify-content: center;
  display: flex;
  background: var(--tonal-red-6521, rgba(255, 105, 92, 0.21));
  border: 0px;
  border-radius: 6px;
  height: 56px;
  width: 262px;
  color: var(--text-red-65, ${({ theme }) => theme.colors.cancelColor});
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 19px;
  &:hover,
  :focus {
    border-color: white;
    background: var(--tonal-red-6521, rgba(255, 105, 92, 0.21));
    color: var(--text-red-65, ${({ theme }) => theme.colors.cancelColor});
  }
`;

export const HourglassContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px;
  gap: 32px;
  width: 118px;
  height: 118px;
  border: 2px dashed ${({ theme }) => theme.colors.fontColor} !important;
  border-radius: 12px;
  background: #ffffff0d;
  margin-bottom: 28px;
`;

export const HourglassIcon = styled(SVG)`
  height: 56px;
  width: 56px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  path {
    fill: ${({ theme }) => theme.colors.iconColorTwo} !important;
  }
`;

export const Hourglass911Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px;
  gap: 32px;
  width: 118px;
  height: 118px;
  border: 2px dashed ${({ theme }) => theme.colors.borderColor911} !important;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.backgroundColor911} !important;
  margin-bottom: 28px;
`;

export const CountdownText911 = styled.div`
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_medium};
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  color: ${({ theme }) => theme.colors.fontColor} !important;
  line-height: 60px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.inter_bold};
  font-size: ${({ theme }) => theme.fontSizes['3xl']};
  margin-bottom: 32px;
  .ant-skeleton {
    .ant-skeleton-content {
      h3 {
        height: 28px !important;
        margin: 0;
      }
    }
  }
  @media (max-width: 1279px) {
    padding: 0px;
    width: 100%;
  }
`;

export const Title = styled(Typography.Title)`
  font-size: ${({ theme }) => theme.fontSizes['2xl']} !important;
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_medium} !important;
  font-weight: ${({ theme }) => theme.fontWeights.medium} !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.primaryColorBase} !important;
  margin-left: 8px;
  margin-bottom: 0px !important;

  .ant-typography-expand {
    color: ${({ theme }) => theme.colors.activeState};
  }
  .ant-typography a:hover,
  .ant-typography-expand:hover {
    color: ${({ theme }) =>
      theme.colors.activeStateRGBA?.replace('{opacity}', '0.6')};
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.sm}) {
    font-size: ${({ theme }) => theme.fontSizes['2xl']};
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }
`;

export const ToggleContainer = styled.div`
  height: 48px;
  width: 200px;
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: flex-end;
  border-radius: 6px;

  @media (max-width: ${({ theme }) => theme.breakPoints.md}) {
    display: inline-flex;
  }
`;

export const ChronometerContainer = styled(Chronometer)`
  color: ${({ theme }) => theme.colors.red} !important;
  width: 100%;
  margin-bottom: 9.5px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  font-size: 19px !important;
`;

export const CircleIcon = styled.span`
  border-radius: 50%;
  background-color: black;
  width: 20px;
  height: 20px;
`;

export const QueueText = styled.div`
  font-family: ${({ theme }) => theme.fonts.stabil_grotesk_medium};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.fontColor} !important;
  line-height: 36px;
  margin-bottom: 8px;
`;

export const QueueTimer = styled.div`
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  div {
    color: ${({ theme }) => theme.colors.queue911FontColor} !important;
  }
`;

export const ConnectingText911 = styled.div`
  font-family: ${({ theme }) => theme.fonts.inter_regular};
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  color: ${({ theme }) => theme.colors.queue911FontColor} !important;
  line-height: 24px;
`;

export const QueueCancelButton = styled(Button)`
  margin-top: 20px;
  padding: 13px 0;
  justify-content: center;
  display: flex;
  background-color: #00000000;
  border: 1px solid;
  border-radius: 6px;
  border-color: ${({ theme }) => theme.colors.cancelColor};
  height: 56px;
  width: 219px;
  color: ${({ theme }) => theme.colors.cancelColor};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: 19px;
  &:hover,
  :focus {
    background-color: #00000000;
    border-color: ${({ theme }) => theme.colors.cancelColor};
    color: ${({ theme }) => theme.colors.cancelColor};
  }
`;

export const QueueTooltip = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 10px;
  right: 0px;
  bottom: 0px;
  background: #006a62;
  border-radius: 99px;
  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;
`;

export const TooltipWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;

export const TooltipContainer = styled(Tooltip)`
  .ant-tooltip-inner {
    padding: 12px;
    gap: 12px;
    position: absolute;
    width: 710px;
    height: 68px;
    left: calc(50% - 710px / 2);
    bottom: 45px;
    box-sizing: border-box;
    border: 1px solid rgba(4, 8, 9, 0.08);
    border-radius: 6px;
    background-color: white;
    font-family: ${({ theme }) => theme.fonts.inter_regular};
    color: black;
    font-weight: 500;
    box-shadow: none;
  }

  .circle-icon {
    margin-right: 12px;
  }
  .tooltip-title {
    display: inline-block;
  }

  .tooltip-description {
    font-family: ${({ theme }) => theme.fonts.inter_regular};
    color: rgba(0, 0, 0, 0.6);
    margin-left: 26px;
  }
`;

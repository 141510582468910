import useAxios from 'axios-hooks';
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import { useContext } from 'react';
import { config } from 'shared/config/cts.config';

type CallInfo = {
  callid: string;
  opentelemetrycontext: string;
  elixirnodeid: string;
  state: string;
  createddt: string;
  isemergency: boolean;
};
export type CallError = { error: string; detail: string };
export type CallData = CallInfo | CallError;

export const useTakeCall = () => {
  const { getUserToken } = useContext(AuthenticationContext);
  const [
    { data: callData, loading: loadingCallData, error: errorCallData },
    get,
  ] = useAxios<CallData>(
    {
      baseURL: `${config.REACT_APP_MR_TAKE_CALL}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  return {
    callData,
    loadingCallData,
    errorCallData,
    get,
  };
};

import { Select } from 'antd';
import styled, { css } from 'styled-components';

export type DropdownButtonType = 'filled' | 'outlined';

export const OverwriteSelectStyles = css`
  .ant-select-dropdown,
  .ant-picker-dropdown > .ant-picker-panel-container {
    border-radius: ${({ theme }) => theme.borderRadius.base};
  }

  .ant-select-dropdown .ant-select-item-option-selected {
    background: ${({ theme }) => theme.colors.dropdownGray};
  }

  .ant-select-dropdown
    .ant-select-item-option-selected
    .ant-select-item-option-content {
    color: ${({ theme }) => theme.colors.textColorBase};
    font-weight: normal !important;
    font-size: ${({ theme }) => theme.fontSizes.md};
  }

  .ant-select-item {
    color: ${({ theme }) => theme.colors.textColorBase};
    font-size: ${({ theme }) => theme.fontSizes.md};
  }

  .ant-select-item:hover {
    background: ${({ theme }) => theme.colors.dropdownGray};
  }

  .ant-select-item-option-content > svg {
    height: 18px;
    width: 18px;
    path {
      fill: ${({ theme }) => theme.colors.textColorBase};
    }
  }

  .ant-select-item-option-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-select-selection-item > *:first-child,
  .ant-select-item-option-content > *:first-child {
    flex-shrink: 0;
  }
`;

export const CustomSelectStyled = styled(Select)<{
  staticWidth?: boolean;
  type: DropdownButtonType;
}>`
  width: ${({ staticWidth }) => (staticWidth ? '200px' : 'auto')};

  .ant-select-selector {
    background-color: ${({ theme, type }) => {
      switch (type) {
        case 'outlined':
          return theme.colors.transparent;
        default:
          return theme.colors.primaryColorBase;
      }
    }} !important;
    border-radius: ${({ theme }) => theme.borderRadius.base} !important;
    border-color: ${({ theme, type }) => {
      switch (type) {
        case 'outlined':
          return theme.colors.inputsBorder;
        default:
          return theme.colors.primaryColorBase;
      }
    }} !important;
    box-shadow: unset !important;
  }

  .anticon > svg > path {
    color: ${({ theme, type }) => {
      switch (type) {
        case 'outlined':
          return theme.colors.gray;
        default:
          return theme.colors.white;
      }
    }};
  }

  .ant-select-selection-item {
    color: ${({ theme, type }) => {
      switch (type) {
        case 'outlined':
          return theme.colors.textColorBase;
        default:
          return theme.colors.white;
      }
    }} !important;
  }

  // Placeholder
  span.ant-select-selection-item[title] {
    color: ${({ theme, type }) => {
      switch (type) {
        case 'outlined':
          return theme.colors.gray;
        default:
          return theme.colors.white;
      }
    }} !important;
    margin-left: 5px !important;
  }

  font-size: ${({ theme }) => theme.fontSizes.md};

  // Dont display the icon (if exists) after an option is selected
  .ant-select-selection-item > *:nth-child(1) {
    margin-top: 2px !important;
  }
`;

export const SVGContainer = styled.span``;

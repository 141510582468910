export enum GatewayEvent {
  authenticate = 'authenticate',
  hello = 'hello',
  ready = 'ready',
  startAudio = 'start_audio',
  captionsEnded = 'captions_ended',
  startCaptions = 'start_captions',
  status = 'status',
  captions = 'captions',
  error = 'error',
  connectionClosed = 'connection_closed',
  alternativesUnavailable = 'alternatives_unavailable',
}

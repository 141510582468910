export interface ICorrectionsPopUpProps {
  shardId: number;
  alternatives: string[];
  text: string;
  onHide: () => void;
  isOpen: boolean;
  setIsOnCorrection: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum keyEventsEnum {
  enter = 'Enter',
  tab = 'Tab',
  one = '1',
  two = '2',
  three = '3',
}

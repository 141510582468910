import * as React from 'react';
import { useEffect } from 'react';
import { ContentEditable, ContentEditableProps } from './ContentEditable';
import { useEditorState } from './EditorContext';
import '../styles.css';

export function Editor({ children, onSelect, ...rest }: EditorProps) {
  const editorState = useEditorState();

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  });

  function onClickOutside(event: MouseEvent) {
    if (event.target === editorState.$el) {
      return;
    }

    if (editorState.$el?.contains(event.target as HTMLElement)) {
      return;
    }

    editorState.update({ $selection: null as any });
  }

  return (
    <div className="rsw-editor">
      {children}
      <ContentEditable {...rest} className="rsw-cts" id={'rsw-cts-editor'} />
    </div>
  );
}

export interface EditorProps extends ContentEditableProps {}

import useAxios from 'axios-hooks';
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import React from 'react';
import { config } from 'shared/config/cts.config';

// This endpoint is not being used currently as it is part of Modern Relay.
// Will be kept for reference purposes.
export const usePostSessionServiceLogin = () => {
  const { getUserToken } = React.useContext(AuthenticationContext);
  const [
    {
      data: dataPostSessionService,
      loading: loadingPostSessionService,
      error: errorPostSessionService,
    },
    post,
  ] = useAxios<any>(
    {
      baseURL: `${config.REACT_APP_SESSION_SERVICE}`,
      url: '/login',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  return {
    post,
    dataPostSessionService,
    loadingPostSessionService,
    errorPostSessionService,
  };
};

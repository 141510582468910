import styled from 'styled-components';

export const AccessDeniedContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

export const AccessDeniedSection = styled.div`
  width: 720px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
`;

export const AccessDeniedBody = styled.div`
  width: 720px;
  height: 122px;
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const AccessDeniedTitle = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(0, 0, 0, 0.89);
`;

export const AccessDeniedContent = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: rgba(0, 0, 0, 0.55);
`;

export const AccessDeniedButton = styled.button`
  width: 200px;
  height: 48px;
  border-radius: 6px;
  box-shadow: none;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  gap: 8px;

  &:hover {
    background-color: #45a049;
  }
`;

export const AccessDeniedButtonLabel = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  color: white;
  text-transform: none;
`;

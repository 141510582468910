import IPCTSSessionProvider from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import LandingPageProvider from 'modules/landing-page/context/landing-page.context';
import { LandingPage } from 'modules/landing-page/landing-page.component';
import applicationRoutes from 'modules/routing/routes';
import {
  IRouteModule,
  RoutePathsFn,
  RoutesModules,
} from 'modules/routing/routes.types';

const landingPageRoutes: RoutePathsFn = () => [
  {
    paths: [applicationRoutes.ROOT, applicationRoutes.CTS_LANDING],
    component: LandingPage,
    // featureFlags: [{ name: 'ctsScreen', value: true }],
  },
];

export const landingPageModule = (): IRouteModule => ({
  name: RoutesModules.HOME,
  routes: landingPageRoutes(),
  providers: [LandingPageProvider, IPCTSSessionProvider],
});

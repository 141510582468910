import { Modal as AntdModal } from 'antd';
import styled from 'styled-components';

export const Modal = styled(AntdModal)`
  .ant-modal-content {
    width: 603px;
    border-radius: 8px;
    .ant-modal-close {
      color: #fff;
      height: 72px;
    }
    .ant-modal-header {
      width: 100%;
      height: 72px;
      padding: 22px 32px 21px;
      border-radius: 8px 8px 2px 2px;
      background-color: ${({ theme }) => theme.colors.modalFontColor};
      .ant-modal-title {
        width: 244px;
        height: 29px;
        font-family: ${({ theme }) => theme.fonts.inter_bold};
        font-size: 24px;
        line-height: normal;
        text-align: left;
        color: #fff;
      }
    }

    .ant-modal-body {
      width: 100%;
      height: 40px;
      margin: 0 0 24px;
      font-family: ${({ theme }) => theme.fonts.inter_regular};
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #404547;

      p {
        margin-bottom: 0px;
      }
    }
    .ant-modal-footer {
      border-top: none;
      .ant-btn {
        width: 175px;
        height: 48px;
        margin: 24px 0 0 16px;
        border-radius: 10px;
        border: solid 2px ${({ theme }) => theme.colors.backgroundColor};

        span {
          font-family: ${({ theme }) => theme.fonts.inter_semibold};
          color: ${({ theme }) => theme.colors.primaryColorBase};
          font-size: ${({ theme }) => theme.fontSizes.md};
        }
      }
      .ant-btn-primary {
        background-color: ${({ theme }) => theme.colors.backgroundColor};
      }
    }
  }
`;

import Background from 'assets/icons/background.svg';
import Hourglass from 'assets/icons/hourglass.svg';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import React, { useContext, useEffect } from 'react';
import * as Styled from 'shared/components/ui/queue-screen/queue-screen.components.styled';
import { UserStates } from 'shared/hooks/admin/states.enum';
import { useUserState } from 'shared/hooks/admin/use-post-update-user-state.hook';
import {
  CallData,
  CallError,
  useTakeCall,
} from 'shared/hooks/modern-relay/use-take-call.hook';
import useAudio from 'shared/hooks/use-audio.hook';

const QueueScreen = () => {
  const {
    showQueue,
    setShowQueue,
    setIsSessionScreen,
    handleQueueCancel,
    setIs911Call,
    is911Call,
    setIsCallActive,
    setCallId,
  } = useContext(LandingPageContext);
  const { updateUserState } = useUserState();
  const { play } = useAudio('/audios/beep.mp3');
  const { get, callData } = useTakeCall();

  const [emergencyTimerSeconds, setEmergencyTimerSeconds] = React.useState(5);

  const handdleStartCall = () => {
    play();
    setIsCallActive(true);
  };

  const isCallError = (data: CallData): data is CallError =>
    (data as CallError).error !== undefined;

  const checkForACall = async () => {
    await get();
    if (!callData || isCallError(callData!)) {
      return;
    }
    setCallId(callData!.callid);

    if (callData!.isemergency) {
      setIs911Call(true);
    } else {
      handdleStartCall();
    }
  };

  useEffect(() => {
    updateUserState(UserStates.IN_QUEUE);
  }, []);

  useEffect(() => {
    if (showQueue) {
      setEmergencyTimerSeconds(5);
      setIs911Call(false);
    }
  }, [showQueue]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkForACall();
    }, 2000);
    return () => {
      clearInterval(intervalId);
    };
  }, [checkForACall]);

  React.useEffect(() => {
    if (is911Call) {
      const intervalId = setInterval(() => {
        setEmergencyTimerSeconds(
          emergencyTimerSeconds > 0
            ? emergencyTimerSeconds - 1
            : emergencyTimerSeconds
        );
      }, 1000);

      if (emergencyTimerSeconds <= 0) {
        handdleStartCall();
      }

      return () => {
        clearInterval(intervalId);
      };
    }
    return () => {};
  }, [emergencyTimerSeconds, is911Call]);

  return (
    <>
      <div>
        <Styled.Background src={Background}> </Styled.Background>
      </div>
      <Styled.QueueContainer>
        {is911Call ? (
          <Styled.Hourglass911Container>
            <div>
              <Styled.CountdownText911>
                {emergencyTimerSeconds}
              </Styled.CountdownText911>
            </div>
          </Styled.Hourglass911Container>
        ) : (
          <Styled.HourglassContainer>
            <div>
              <Styled.HourglassIcon src={Hourglass} />
            </div>
          </Styled.HourglassContainer>
        )}

        {is911Call ? (
          <Styled.QueueText>Entering Emergency Call</Styled.QueueText>
        ) : (
          <Styled.QueueText>You’re in queue for a call</Styled.QueueText>
        )}

        {is911Call ? (
          <Styled.QueueTimer>
            <Styled.ConnectingText911>Connecting...</Styled.ConnectingText911>
          </Styled.QueueTimer>
        ) : (
          <Styled.QueueTimer>
            <Styled.ChronometerContainer id="queueTimer" inQueue />
          </Styled.QueueTimer>
        )}
        <Styled.QueueCancelButton
          id="cancelButton"
          onClick={() => {
            setShowQueue(false);
            setIsSessionScreen(false);
            handleQueueCancel();
          }}
        >
          Cancel
        </Styled.QueueCancelButton>
      </Styled.QueueContainer>
    </>
  );
};

export default QueueScreen;

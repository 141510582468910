import useAxios from 'axios-hooks';
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import React from 'react';
import { config } from 'shared/config/cts.config';
import { UpdateSessionReqModel } from 'shared/datasources/session-state-service/entities.dto';

// This endpoint is not being used currently as it is part of Modern Relay.
// Will be kept for reference purposes.
export const usePostSessionServiceUpdate = () => {
  const { getUserToken } = React.useContext(AuthenticationContext);
  const [
    {
      data: dataPostSessionService,
      loading: loadingPostSessionService,
      error: errorPostSessionService,
    },
    post,
  ] = useAxios<UpdateSessionReqModel>(
    {
      baseURL: `${config.REACT_APP_SESSION_SERVICE}`,
      url: '/update',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
      },
    },
    {
      manual: true,
      autoCancel: false,
    }
  );

  return {
    post,
    dataPostSessionService,
    loadingPostSessionService,
    errorPostSessionService,
  };
};
